import React, { useEffect, useState } from "react";
import * as bodyText from "../SearchProps";
import * as S from "./RecentSearchBig.styled";

import { useMediaQuery, useTheme } from "@material-ui/core";
import axios from "axios";
import { observer } from "mobx-react";
import CheckSquareSettingsIcon from "../../../images/CheckSquareSettingsIcon.png";
import CodeBranchSettingsIcon from "../../../images/CodeBranchSettingsIcon.png";
import DownloadSettingsIcon from "../../../images/DownloadSettingsIcon.png";
import HistorySettingsIcon from "../../../images/HistorySettingsIcon.png";
import LevelUpSettingsIcon from "../../../images/LevelUpSettingsIcon.png";
import MapSettingsIcon from "../../../images/MapSettingsIcon.png";
import RefreshIcon from "../../../images/RefreshIcon.png";
import SlidersIcon from "../../../images/SlidersIcon.png";
import sleep from "../../../utils/sleep";
import useStore from "../../../utils/useStore";
import Equipment from "../Equipment";
import HeadUnitActivation from "../Modals/HeadUnitActivation";
import HeadUnitChoose from "../Modals/HeadUnitChoose";
import HeadUnitMethod from "../Modals/HeadUnitMethod";

const RecentSearchBig = ({
  carProps,
  services,
  regions,
  regionVersion,
  isSearching,
  setIsSearching,
  finished,
  setFinished,
  currentVin,
  setCurrentVin,
}) => {
  const [TooltipOpen, setTooltipOpen] = useState(false);
  const [openEquipment, setOpenEquipment] = useState(false);
  const [methodBody, setmethodBody] = useState(null);
  const [leftBody, setleftBody] = useState(null);
  const [rightBody, setrightBody] = useState(null);
  const [openDialogName, setOpenDialogName] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [refreshDate, setRefreshDate] = useState();
  const { store } = useStore();
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    let miliseconds = new Date() - new Date(carProps.updated_at);

    let seconds = (miliseconds / 1000).toFixed(0);
    let minutes = (miliseconds / (1000 * 60)).toFixed(0);
    let hours = (miliseconds / (1000 * 60 * 60)).toFixed(0);
    let days = (miliseconds / (1000 * 60 * 60 * 24)).toFixed(0);

    if (days > 0) {
      setRefreshDate(days + " Days ago");
    } else if (hours > 0) {
      setRefreshDate(hours + " Hours ago");
    } else if (minutes > 0) {
      setRefreshDate(minutes + " Minutes ago");
    } else if (seconds > 0) {
      setRefreshDate(seconds + " Seconds ago");
    } else {
      setRefreshDate(" Recently");
    }
  }, []);

  const handleTooltipOpen = (event) => {
    setTooltipOpen(!TooltipOpen);
  };

  const handleEquipmentOpen = (event) => {
    setOpenEquipment(true);
  };

  const handleHeadUnitActivationMethodOpen = () => {
    switch (carProps.headunit) {
      case "NBT EVO ID5/6":
        return openDialog(
          bodyText.activationMethodBody,
          "HeadUnitMethod",
          getBody("featureCodeBody"),
          getBody("usbPackBody")
        );
      case "NBT EVO ID4":
        return openDialog(getBody("headunitnbtEVO4"), "HeadUnitActivation");
      case "CIC":
        return openDialog(getBody("cicActivationBody"), "HeadUnitActivation");
      case "MGU":
        return openDialog(getBody("mguActivationBody"), "HeadUnitActivation");
      default:
        return openDialog(getBody("headunitnbtNext"), "HeadUnitActivation");
    }
  };

  const handleHeadUnitCodingMethodOpen = () => {
    openDialog(
      bodyText.codingMethodBody,
      "HeadUnitMethod",
      bodyText.simpleCodingBody,
      bodyText.advancedBodyCoding
    );
  };

  const handleMapCodeOpen = (event) => {
    carProps.headunit === "NBT EVO ID4"
      ? openDialog(getBody("ID4mapCode"), "HeadUnitActivation")
      : carProps.map.includes("Route")
      ? openDialog(getBody("routemapCode"), "HeadUnitActivation")
      : openDialog(getBody("mapCode"), "HeadUnitActivation");
  };

  const handleLifeTimeMapCodeOpen = (event) => {
    carProps.headunit === "NBT EVO ID4"
      ? openDialog(getBody("ID4mapCodeLifetime"), "HeadUnitActivation")
      : carProps.map.includes("Route")
      ? openDialog(getBody("routemapCodelifetime"), "HeadUnitActivation")
      : openDialog(getBody("mapCodeLifetime"), "HeadUnitActivation");
  };

  const handleRepairPackOpen = (event) => {
    openDialog(getBody("repairPackBody"), "HeadUnitActivation");
  };

  const handleCarHistoryOpen = (event) => {
    openDialog(getBody("carHistoryBody"), "HeadUnitActivation");
  };

  const handleRetrofitHeadUnitOpen = (event) => {
    openDialog(
      bodyText.retrofitHeadunitBody,
      "HeadUnitChoose",
      getBody("featureCodeBody"),
      getBody("usbPackBody")
    );
  };

  const openDialog = (body, component, leftBody, rightBody) => {
    setmethodBody(body);
    setOpen(true);
    setOpenDialogName(component);
    setrightBody(rightBody);
    setleftBody(leftBody);
    handleTooltipOpen();
  };

  const getBody = (bodyName) => {
    return {
      ...bodyText[bodyName],
      ...services.find((el) => {
        return el.title === bodyText[bodyName].bodyId;
      }),
    };
  };

  const getRefreshedAgo = () => {
    setCurrentVin(carProps.vin);
    setIsSearching(true);
    setFinished(false);
    const res = axios
      .post(
        process.env.REACT_APP_API_URL + "/search",
        {
          vin: carProps.vin,
        },
        {
          withCredentials: true,
        }
      )
      .then(async (res) => {
        setFinished(true);
        await sleep(1000);
        setIsSearching(false);
        setSearchResults(res.data);
      })
      .catch(async (error) => {
        store.callToast(error.response.data, "Error");
        await sleep(1000);
        setIsSearching(false);
        setFinished(true);
      });
    if (!window.location.href.includes("Search")) {
      window.open(process.env.REACT_APP_URL + "/Search", "_self");
    }
  };

  return (
    <>
      <Equipment
        isOpen={openEquipment}
        setIsOpen={setOpenEquipment}
        carProps={carProps}
      />

      {openDialogName === "HeadUnitActivation" ? (
        <HeadUnitActivation
          isOpen={open}
          setIsOpen={setOpen}
          carProps={carProps}
          body={methodBody}
          services={services}
          regions={regions}
          regionVersions={regionVersion}
        />
      ) : null}
      {openDialogName === "HeadUnitChoose" ? (
        <HeadUnitChoose
          isOpen={open}
          setIsOpen={setOpen}
          carProps={carProps}
          body={methodBody}
          leftModal={leftBody}
          rightModal={rightBody}
          nbtnextModal={
            methodBody.bodyId === "simpleCodingBody"
              ? getBody("simpleHeadUnitBinPacksnbtnext")
              : methodBody.bodyId === "advancedBodyCoding"
              ? getBody("advancedHeadUnitBinPacksnbtnext")
              : getBody("headunitnbtNext")
          }
          nbtevo4Modal={
            methodBody.bodyId === "simpleCodingBody"
              ? getBody("simpleHeadUnitBinPacksnbtEVO4")
              : methodBody.bodyId === "advancedBodyCoding"
              ? getBody("advancedHeadUnitBinPacksnbtEVO4")
              : getBody("headunitnbtEVO4")
          }
          nbtevo56Modal={
            methodBody.bodyId === "simpleCodingBody"
              ? getBody("simpleHeadUnitBinPacksnbtEVO56")
              : methodBody.bodyId === "advancedBodyCoding"
              ? getBody("advancedHeadUnitBinPacksnbtEVO56")
              : bodyText.activationMethodBody
          }
          nextModal={{
            setNextMethodBody: setmethodBody,
            setNextDialog: setOpenDialogName,
          }}
        />
      ) : null}

      {openDialogName === "HeadUnitMethod" ? (
        <HeadUnitMethod
          isOpen={open}
          setIsOpen={setOpen}
          carProps={carProps}
          body={methodBody}
          leftModal={leftBody}
          rightModal={rightBody}
          nextModal={{
            setNextMethodBody: setmethodBody,
            setNextDialog: setOpenDialogName,
          }}
        />
      ) : null}

      <S.Wrapper>
        <S.Container>
          <S.ImgColumn style={{ width: "30%" }}>
            <S.Img src={carProps.image}></S.Img>
          </S.ImgColumn>
          <S.SearchColumn>
            <S.SearchRow
              style={{
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "140px",
              }}
            >
              <S.InfoColumn style={{ width: "180px" }}>
                <S.RecentSearchTypography variant="CarName">
                  {carProps.brand}
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Subtitle">
                  {carProps.model}
                </S.RecentSearchTypography>
              </S.InfoColumn>

              <S.InfoColumn style={{ width: "300px" }}>
                <S.SearchRow style={{ justifyContent: "end" }}>
                  <S.ButtonSettings
                    variant="outlined"
                    onClick={handleEquipmentOpen}
                  >
                    <img src={SlidersIcon} width="15" height="15"></img>
                    <S.RecentSearchTypography variant="Settings">
                      Equipment
                    </S.RecentSearchTypography>
                  </S.ButtonSettings>

                  <S.RefreshButton onClick={getRefreshedAgo}>
                    <S.RecentSearchTypography variant="Refresh">
                      {refreshDate}
                    </S.RecentSearchTypography>
                    <img src={RefreshIcon} width="12" height="12"></img>
                  </S.RefreshButton>
                </S.SearchRow>
              </S.InfoColumn>
            </S.SearchRow>

            <S.SearchRow
              style={isSmall ? { justifyContent: "space-between" } : {}}
            >
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Development code
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.development_code}
                </S.RecentSearchTypography>
              </S.InfoColumn>
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  I-Level
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.i_level}
                </S.RecentSearchTypography>
              </S.InfoColumn>
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Production date
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.date}
                </S.RecentSearchTypography>
              </S.InfoColumn>
            </S.SearchRow>
            <S.SearchRow
              style={isSmall ? { justifyContent: "space-between" } : {}}
            >
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Headunit
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.headunit}
                </S.RecentSearchTypography>
              </S.InfoColumn>
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Short VIN (7 digits)
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.vin_short}
                </S.RecentSearchTypography>
              </S.InfoColumn>
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Engine
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.engine}
                </S.RecentSearchTypography>
              </S.InfoColumn>
            </S.SearchRow>
            <S.SearchRow
              style={isSmall ? { justifyContent: "space-between" } : {}}
            >
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Installed map
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.map}
                </S.RecentSearchTypography>
              </S.InfoColumn>
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  VIN (17 digits)
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.vin}
                </S.RecentSearchTypography>
              </S.InfoColumn>
              <S.InfoColumn>
                <S.RecentSearchTypography variant="Title">
                  Last mileage
                </S.RecentSearchTypography>
                <S.RecentSearchTypography variant="Info">
                  {carProps.last_milege}
                </S.RecentSearchTypography>
              </S.InfoColumn>
            </S.SearchRow>
          </S.SearchColumn>
        </S.Container>

        <S.SearchColumn>
          <S.RecentSearchTypography variant="SearchTitle">
            Choose a Service
          </S.RecentSearchTypography>
          <S.SearchRow>
            {/* {carProps.headunit !== "-" && (
              <S.SearchColumn>
                <S.ServiceButton onClick={handleHeadUnitCodingMethodOpen}>
                  <img
                    src={DownloadSettingsIcon}
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  ></img>
                  <S.RecentSearchTypography variant="Settings">
                    Headunit Coding
                  </S.RecentSearchTypography>
                </S.ServiceButton>
              </S.SearchColumn>
            )} */}
            <S.SearchColumn>
              <S.ServiceButton onClick={handleRepairPackOpen}>
                <img
                  src={DownloadSettingsIcon}
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                ></img>
                <S.RecentSearchTypography variant="Settings">
                  Repair pack download
                </S.RecentSearchTypography>
              </S.ServiceButton>
            </S.SearchColumn>
            <S.SearchColumn>
              <S.ServiceButton onClick={handleCarHistoryOpen}>
                <img
                  src={HistorySettingsIcon}
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                ></img>
                <S.RecentSearchTypography variant="Settings">
                  Car history
                </S.RecentSearchTypography>
              </S.ServiceButton>
            </S.SearchColumn>
            <S.SearchColumn>
              <S.ServiceButton onClick={handleRetrofitHeadUnitOpen}>
                <img
                  src={LevelUpSettingsIcon}
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                ></img>
                <S.RecentSearchTypography variant="Settings">
                  Retrofit headunit
                </S.RecentSearchTypography>
              </S.ServiceButton>
            </S.SearchColumn>
            {carProps.map !== "-" && (
              <>
                <S.SearchColumn>
                  <S.ServiceButton onClick={handleMapCodeOpen}>
                    <img
                      src={CodeBranchSettingsIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    ></img>
                    <S.RecentSearchTypography variant="Settings">
                      Map Code
                    </S.RecentSearchTypography>
                  </S.ServiceButton>
                </S.SearchColumn>

                <S.SearchColumn>
                  <S.ServiceButton onClick={handleLifeTimeMapCodeOpen}>
                    <img
                      src={MapSettingsIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    ></img>
                    <S.RecentSearchTypography variant="Settings">
                      Lifetime Map Code
                    </S.RecentSearchTypography>
                  </S.ServiceButton>
                </S.SearchColumn>
              </>
            )}
            {carProps.headunit !== "-" && (
              <S.SearchColumn>
                <S.ServiceButton onClick={handleHeadUnitActivationMethodOpen}>
                  <img
                    src={CheckSquareSettingsIcon}
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  ></img>
                  <S.RecentSearchTypography variant="Settings">
                    Headunit activation
                  </S.RecentSearchTypography>
                </S.ServiceButton>
              </S.SearchColumn>
            )}
          </S.SearchRow>
        </S.SearchColumn>
      </S.Wrapper>
    </>
  );
};
export default observer(RecentSearchBig);
