import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@mui/material/Box";
import axios from "axios";
import { observer } from "mobx-react";
import React, { useState } from "react";
import isLength from "validator/es/lib/isLength";
import sleep from "../../../utils/sleep";
import useStore from "../../../utils/useStore";
import * as S from "./SearchBar.styled";
const SearchBar = ({
  isSearching,
  setIsSearching,
  currentVin,
  setCurrentVin,
  finished,
  setFinished,
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState(null);
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [searchResults, setSearchResults] = useState(null);
  const { store } = useStore();

  const getRecentSearches = () => {
    setIsSearching(true);
    setCurrentVin(search);
    const res = axios
      .post(
        process.env.REACT_APP_API_URL + "/search",
        {
          vin: search,
        },
        {
          withCredentials: true,
        }
      )
      .then(async (res) => {
        setFinished(true);
        await sleep(1000);
        setIsSearching(false);
        setSearchResults(res.data);
        if (!window.location.href.includes("Search")) {
          window.open(process.env.REACT_APP_URL + "/Search", "_self");
        }
      })
      .catch(async (error) => {
        store.callToast(error.response.data, "Error");
        await sleep(1000);
        setIsSearching(false);
        setFinished(true);
      });
  };

  const vinLength =
    search !== null
      ? isLength(search, { min: 7, max: 7 }) ||
        isLength(search, { min: 17, max: 17 })
      : true;
  const vinUppercase = search !== null ? search.toUpperCase() === search : true;

  const vinIncludes =
    search !== null
      ? !search.includes("O") && !search.includes("I") && !search.includes("Q")
      : true;

  return (
    <>
      <S.SearchWrapper style={isExtraSmall ? { flexDirection: "column" } : {}}>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <S.StyledTextField
            size="medium"
            id="Search"
            InputProps={{
              startAdornment: (
                <FontAwesomeIcon icon="search" style={{ marginRight: 10 }} />
              ),
            }}
            label="Enter VIN (17 or 7 digits)"
            variant="outlined"
            type="search"
            InputLabelProps={{
              style: { color: "#6a6a6a" },
            }}
            onChange={(e) => setSearch(e.target.value.trim())}
            value={search}
            error={!vinLength || !vinIncludes || !vinUppercase}
            helperText={
              !vinLength
                ? "VIN must be either 7 or 17 Digits"
                : !vinIncludes
                ? "Vin is incorrect. It cannot contain the letters I, O or Q"
                : !vinUppercase
                ? "Vin can only be lowercase"
                : null
            }
          />
        </Box>

        <S.SearchButton
          disabled={!vinLength || !vinIncludes || !vinUppercase || !search}
          size="small"
          onClick={getRecentSearches}
          style={
            isExtraSmall
              ? { marginTop: "10px", minWidth: "280px" }
              : { marginLeft: "10px" }
          }
        >
          <S.SearchTypography variant="search">Check VIN</S.SearchTypography>
        </S.SearchButton>
      </S.SearchWrapper>
    </>
  );
};
export default observer(SearchBar);
