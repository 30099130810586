import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faQuestionCircle,
  faHome,
  faLock,
  faShoppingCart,
  faQuestion,
  faGift,
  faSearch,
  faCircle,
  faCheckCircle,
  faPlusCircle,
  faMoneyCheck,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faWallet,
  faMoneyCheck,
  faHome,
  faLock,
  faShoppingCart,
  faQuestion,
  faGift,
  faSearch,
  faBars,
  faQuestionCircle,
  faCircle,
  faCheckCircle,
  faPlusCircle
);
