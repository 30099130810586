import { React, useState, useContext } from "react";
import { Grid, Avatar, TextField } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useTheme } from "@material-ui/core/styles";
import * as S from "./ResetPassword.styled";
import { StyledButton, StyledTypography } from "../../GlobalComponents";
import axios from "axios";
import isLength from "validator/es/lib/isLength";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import ContextApi from "../../ContextApi";
import useStore from "../../utils/useStore";
import { observer } from "mobx-react";

const ResetPassword = ({ isOpen, setIsOpen }) => {
  const theme = useTheme();
  const [password, setPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const params = useParams();
  const PropsContext = useContext(ContextApi);
  const { store } = useStore();

  const isPasswordValid =
    password !== null ? isLength(password, { min: 7, max: 30 }) : true;
  const isRepeatPasswordValid =
    repeatPassword !== null
      ? isLength(repeatPassword, { min: 7, max: 30 }) &&
        isPasswordValid &&
        password === repeatPassword
      : true;

  const handleResetPassword = (event) => {
    const res = axios
      .post(process.env.REACT_APP_API_URL + "/reset", {
        token: params.id,
        password,
      })
      .then(async (res) => {
        Cookies.set("access_token", res.data.accessToken);
        Cookies.set("jwt", res.data.accessToken, {
          domain: ".bavarianvin.com",
          secure: false,
        });
        await store.refresh();
        PropsContext.setAuth(true);
        store.callToast(
          "Your password has been successfully changed.",
          "success"
        );
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  return (
    <S.Container>
      <S.resetContainer>
        <Grid align="center">
          <Avatar style={{ backgroundColor: "#027bfe" }}>
            <LockOutlinedIcon />
          </Avatar>
          <StyledTypography
            variant="h2"
            style={{
              marginBottom: theme.spacing(3),
              color: theme.palette.primary.light,
            }}
          >
            Forgot password
          </StyledTypography>
        </Grid>
        <TextField
          fullWidth
          type="password"
          value={password || ""}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          style={{ marginBottom: theme.spacing(2) }}
          label="Password"
          variant="filled"
          error={!isPasswordValid}
          helperText={isPasswordValid ? null : "Too short"}
        />
        <TextField
          fullWidth
          type="password"
          value={repeatPassword || ""}
          onChange={(e) => setRepeatPassword(e.target.value)}
          style={{ marginBottom: theme.spacing(2) }}
          label="Repeat password"
          variant="filled"
          error={!isRepeatPasswordValid}
          helperText={
            isRepeatPasswordValid
              ? null
              : "Too short or does not match password"
          }
        />
        <StyledButton
          onClick={handleResetPassword}
          disabled={!isPasswordValid || !isRepeatPasswordValid || !password}
          fullWidth
        >
          Reset Password
        </StyledButton>
      </S.resetContainer>
    </S.Container>
  );
};
export default observer(ResetPassword);
