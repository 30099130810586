import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as S from "./Snackbar.styled";
import { observer } from "mobx-react";
import useStore from "../../utils/useStore";

const CustomizedSnackbars = () => {
  const { store } = useStore();
  const handleClose = () => {
    store.isToastOpen = false;
  };

  return (
    <S.ToastContainer>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={store.isToastOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        disableWindowBlurListener={true}
      >
        <Alert
          style={{ paddingRight: "20px" }}
          open={store.isToastOpen}
          elevation={6}
          variant="filled"
          color={store.Toast.type}
          onClose={handleClose}
        >
          {store.Toast.text}
        </Alert>
      </Snackbar>
    </S.ToastContainer>
  );
};

export default observer(CustomizedSnackbars);
