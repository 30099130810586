import React, { useState } from "react";
import { StyledTypography, StyledButton } from "../../GlobalComponents";

import peopleIcon from "../../images/PeopleIcon.png";
import coinIcon from "../../images/CoinIcon.png";
import { useTheme, useMediaQuery } from "@material-ui/core";
import * as S from "./Invite.styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useStore from "../../utils/useStore";
import { observer } from "mobx-react";

const Invite = () => {
  const { store } = useStore();
  const theme = useTheme();
  const [code, setCode] = useState(localStorage.getItem("inviteCode"));

  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <S.Container>
      <S.Wrapper style={isExtraSmall ? { flexDirection: "column" } : {}}>
        <S.LeftContainer>
          <S.Title>
            <StyledTypography variant="h5">
              Invite Your Friends And Earn!
            </StyledTypography>
          </S.Title>
          <StyledTypography
            variant="subtitle2"
            style={{
              paddingBottom: "10px",
            }}
          >
            You will earn 10% every time your invitees buy credits. For example,
            if your friend buys 100 credits, you get 10 credits for free!
          </StyledTypography>
          <S.Title style={isExtraSmall ? { flexDirection: "column" } : {}}>
            <StyledTypography variant="h5">Your invite code</StyledTypography>
            <CopyToClipboard text={code}>
              <StyledButton
                style={
                  isExtraSmall
                    ? { marginLeft: "0px", maxWidth: "200px" }
                    : { marginLeft: "10px" }
                }
                onClick={() => store.callToast("Copied!", "success")}
              >
                {code}
              </StyledButton>
            </CopyToClipboard>
          </S.Title>
        </S.LeftContainer>
        <S.RightContainer>
          {localStorage.getItem("userFromInvites") && (
            <S.StyledBadgePeople
              badgeContent={
                <img src={peopleIcon} alt="Logo" height={10} width={15} />
              }
            >
              <StyledTypography variant="h5">
                {localStorage.getItem("userFromInvites")}
              </StyledTypography>
            </S.StyledBadgePeople>
          )}
          <StyledTypography
            variant="h4"
            style={{
              color: "#8e8f98",
              fontWeight: 100,
              paddingBottom: "10px",
            }}
          >
            Users invited
          </StyledTypography>
          {localStorage.getItem("creditsFromInvites") && (
            <S.StyledBadgeCoin
              badgeContent={
                <img src={coinIcon} alt="Logo" height={10} width={10} />
              }
            >
              <StyledTypography variant="h5">
                {localStorage.getItem("creditsFromInvites")}
              </StyledTypography>
            </S.StyledBadgeCoin>
          )}
          <StyledTypography
            variant="h4"
            style={{
              color: "#8e8f98",
              fontWeight: 100,
            }}
          >
            Credits earned from invites
          </StyledTypography>
        </S.RightContainer>
      </S.Wrapper>
    </S.Container>
  );
};

export default observer(Invite);
