import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { StyledTypography } from "../../GlobalComponents";
import { StyledContainer } from "../../GlobalComponents";

export const Container = styled(StyledContainer)``;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

export const HeaderTitle = styled(StyledTypography)`
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const StyledHelpOutlineIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    top: "3px",
    marginRight: 5,
    position: "relative",
  },
}))(HelpOutlineIcon);

export const StyledAccordion = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
    border: "solid 0.5px #d1d8e3",
  },
}))(Accordion);
