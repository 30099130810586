import { useMediaQuery, useTheme } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import * as React from "react";
import * as S from "./Equipment.styled";

const Equipment = ({ isOpen, setIsOpen, carProps }) => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.StyledDialog onClose={() => setIsOpen(false)} open={isOpen}>
      <S.TitleWrapper
        style={isSmall ? { flexFlow: "column", alignItems: "center" } : {}}
      >
        <S.TitleColumn style={{ width: "30%" }}>
          <Avatar
            style={
              isSmall
                ? {
                    display: "none",
                    width: "50%",
                    alignItems: "center",
                    marginBottom: theme.spacing(1),
                  }
                : { backgroundColor: "white", width: "80%", height: "160%" }
            }
          >
            <S.Img src={carProps.image}></S.Img>
          </Avatar>
        </S.TitleColumn>
        <S.TitleColumn>
          <S.TitleRow>
            <S.EquipmentTypography variant="Title">
              {carProps.vin}
            </S.EquipmentTypography>
          </S.TitleRow>
          <S.TitleRow>
            <S.EquipmentTypography variant="Subtitle">
              BMW SAT 320D XDRIVE 135 [kW]
            </S.EquipmentTypography>
          </S.TitleRow>
        </S.TitleColumn>
      </S.TitleWrapper>
      <S.InfoRow>
        <S.InfoColumn>
          <S.InfoRow>
            <S.EquipmentTypography variant="BodyTitle">
              Optional equipment:
            </S.EquipmentTypography>
          </S.InfoRow>
          {carProps.optional_equipment.map((optional_equipment) => (
            <S.InfoRow>
              <S.EquipmentTypography variant="Body">
                {optional_equipment}
              </S.EquipmentTypography>
            </S.InfoRow>
          ))}
        </S.InfoColumn>
        <S.InfoColumn>
          <S.InfoRow>
            <S.EquipmentTypography variant="BodyTitle">
              Retrofitted optional equipment:
            </S.EquipmentTypography>
          </S.InfoRow>
          {carProps.retrofited_equipment.map((retrofited_equipment) => (
            <S.InfoRow>
              <S.EquipmentTypography variant="Body">
                {retrofited_equipment}
              </S.EquipmentTypography>
            </S.InfoRow>
          ))}
        </S.InfoColumn>
      </S.InfoRow>
    </S.StyledDialog>
  );
};
export default Equipment;
