import React, { useEffect, useState } from "react";
import * as bodyText from "../SearchProps";
import * as S from "./RecentSearch.styled";

import { useMediaQuery, useTheme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grow from "@mui/material/Grow";
import RefreshIcon from "../../../images/RefreshIcon.png";
import SettingCogIcon from "../../../images/SettingCogIcon.png";
import SlidersIcon from "../../../images/SlidersIcon.png";

import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import CheckSquareSettingsIcon from "../../../images/CheckSquareSettingsIcon.png";
import CodeBranchSettingsIcon from "../../../images/CodeBranchSettingsIcon.png";
import DownloadSettingsIcon from "../../../images/DownloadSettingsIcon.png";
import HistorySettingsIcon from "../../../images/HistorySettingsIcon.png";
import LevelUpSettingsIcon from "../../../images/LevelUpSettingsIcon.png";
import MapSettingsIcon from "../../../images/MapSettingsIcon.png";

import Equipment from "../Equipment";

import axios from "axios";
import { observer } from "mobx-react";
import sleep from "../../../utils/sleep";
import useStore from "../../../utils/useStore";
import HeadUnitActivation from "../Modals/HeadUnitActivation";
import HeadUnitChoose from "../Modals/HeadUnitChoose";
import HeadUnitMethod from "../Modals/HeadUnitMethod";

const RecentSearchComponent = ({
  carProps,
  services,
  regions,
  regionVersion,
  isSearching,
  setIsSearching,
  finished,
  setFinished,
  currentVin,
  setCurrentVin,
}) => {
  const [TooltipOpen, setTooltipOpen] = useState(false);
  const [openEquipment, setOpenEquipment] = useState(false);
  const { store } = useStore();
  const [methodBody, setmethodBody] = useState(null);
  const [leftBody, setleftBody] = useState(null);
  const [rightBody, setrightBody] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [OpenExpand, setOpenExpand] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openDialogName, setOpenDialogName] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [refreshDate, setRefreshDate] = useState();

  useEffect(() => {
    let miliseconds = new Date() - new Date(carProps.updated_at);

    let seconds = (miliseconds / 1000).toFixed(0);
    let minutes = (miliseconds / (1000 * 60)).toFixed(0);
    let hours = (miliseconds / (1000 * 60 * 60)).toFixed(0);
    let days = (miliseconds / (1000 * 60 * 60 * 24)).toFixed(0);

    if (days > 0) {
      setRefreshDate(days + " Days ago");
    } else if (hours > 0) {
      setRefreshDate(hours + " Hours ago");
    } else if (minutes > 0) {
      setRefreshDate(minutes + " Minutes ago");
    } else if (seconds > 0) {
      setRefreshDate(seconds + " Seconds ago");
    }
  }, []);

  const handleTooltipOpen = (event) => {
    setTooltipOpen(!TooltipOpen);
  };
  const handleExpandOpen = (event) => {
    setChecked((prev) => !prev);
    OpenExpand ? setOpenExpand(false) : setOpenExpand(true);
  };

  const handleEquipmentOpen = (event) => {
    setOpenEquipment(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleHeadUnitActivationMethodOpen = () => {
    switch (carProps.headunit) {
      case "NBT EVO ID5/6":
        return openDialog(
          bodyText.activationMethodBody,
          "HeadUnitMethod",
          getBody("featureCodeBody"),
          getBody("usbPackBody")
        );
      case "NBT EVO ID4":
        return openDialog(getBody("headunitnbtEVO4"), "HeadUnitActivation");
      case "CIC":
        return openDialog(getBody("cicActivationBody"), "HeadUnitActivation");
      case "MGU":
        return openDialog(getBody("mguActivationBody"), "HeadUnitActivation");
      default:
        return openDialog(getBody("headunitnbtNext"), "HeadUnitActivation");
    }
  };

  const handleHeadUnitCodingMethodOpen = () => {
    openDialog(
      bodyText.codingMethodBody,
      "HeadUnitMethod",
      bodyText.simpleCodingBody,
      bodyText.advancedBodyCoding
    );
  };

  const handleMapCodeOpen = (event) => {
    carProps.headunit === "NBT EVO ID4"
      ? openDialog(getBody("ID4mapCode"), "HeadUnitActivation")
      : carProps.map.includes("Route")
      ? openDialog(getBody("routemapCode"), "HeadUnitActivation")
      : openDialog(getBody("mapCode"), "HeadUnitActivation");
  };

  const handleLifeTimeMapCodeOpen = (event) => {
    carProps.headunit === "NBT EVO ID4"
      ? openDialog(getBody("ID4mapCodeLifetime"), "HeadUnitActivation")
      : carProps.map.includes("Route")
      ? openDialog(getBody("routemapCodelifetime"), "HeadUnitActivation")
      : openDialog(getBody("mapCodeLifetime"), "HeadUnitActivation");
  };

  const handleRepairPackOpen = (event) => {
    openDialog(getBody("repairPackBody"), "HeadUnitActivation");
  };

  const handleCarHistoryOpen = (event) => {
    openDialog(getBody("carHistoryBody"), "HeadUnitActivation");
  };

  const handleRetrofitHeadUnitOpen = (event) => {
    openDialog(
      bodyText.retrofitHeadunitBody,
      "HeadUnitChoose",
      getBody("featureCodeBody"),
      getBody("usbPackBody")
    );
  };
  const openDialog = (body, component, leftBody, rightBody) => {
    setmethodBody(body);
    setOpen(true);
    setOpenDialogName(component);
    setrightBody(rightBody);
    setleftBody(leftBody);
    handleTooltipOpen();
  };

  const getBody = (bodyName) => {
    return {
      ...bodyText[bodyName],
      ...services.find((el) => {
        return el.title === bodyText[bodyName].bodyId;
      }),
    };
  };

  const getRefreshedAgo = async () => {
    setCurrentVin(carProps.vin);
    setIsSearching(true);
    setFinished(false);

    const res = await axios
      .post(
        process.env.REACT_APP_API_URL + "/search",
        {
          vin: carProps.vin,
        },
        {
          withCredentials: true,
        }
      )
      .then(async (res) => {
        setFinished(true);
        await sleep(1000);
        setIsSearching(false);
        setSearchResults(res.data);
      })
      .catch(async (error) => {
        store.callToast(error.response.data, "Error");
        await sleep(1000);
        setIsSearching(false);
        setFinished(true);
      });
    if (!window.location.href.includes("Search")) {
      window.open(process.env.REACT_APP_URL + "/Search", "_self");
    }
  };

  return (
    <>
      <Equipment
        isOpen={openEquipment}
        setIsOpen={setOpenEquipment}
        carProps={carProps}
      />

      {openDialogName === "HeadUnitActivation" ? (
        <HeadUnitActivation
          isOpen={open}
          setIsOpen={setOpen}
          carProps={carProps}
          body={methodBody}
          services={services}
          regions={regions}
          regionVersions={regionVersion}
        />
      ) : null}
      {openDialogName === "HeadUnitChoose" ? (
        <HeadUnitChoose
          isOpen={open}
          setIsOpen={setOpen}
          carProps={carProps}
          body={methodBody}
          leftModal={leftBody}
          rightModal={rightBody}
          nbtnextModal={
            methodBody.bodyId === "simpleCodingBody"
              ? getBody("simpleHeadUnitBinPacksnbtnext")
              : methodBody.bodyId === "advancedBodyCoding"
              ? getBody("advancedHeadUnitBinPacksnbtnext")
              : getBody("headunitnbtNext")
          }
          nbtevo4Modal={
            methodBody.bodyId === "simpleCodingBody"
              ? getBody("simpleHeadUnitBinPacksnbtEVO4")
              : methodBody.bodyId === "advancedBodyCoding"
              ? getBody("advancedHeadUnitBinPacksnbtEVO4")
              : getBody("headunitnbtEVO4")
          }
          nbtevo56Modal={
            methodBody.bodyId === "simpleCodingBody"
              ? getBody("simpleHeadUnitBinPacksnbtEVO56")
              : methodBody.bodyId === "advancedBodyCoding"
              ? getBody("advancedHeadUnitBinPacksnbtEVO56")
              : bodyText.activationMethodBody
          }
          nextModal={{
            setNextMethodBody: setmethodBody,
            setNextDialog: setOpenDialogName,
          }}
        />
      ) : null}

      {openDialogName === "HeadUnitMethod" ? (
        <HeadUnitMethod
          isOpen={open}
          setIsOpen={setOpen}
          carProps={carProps}
          body={methodBody}
          leftModal={leftBody}
          rightModal={rightBody}
          nextModal={{
            setNextMethodBody: setmethodBody,
            setNextDialog: setOpenDialogName,
          }}
        />
      ) : null}
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <S.styledPaper
          elevation={3}
          style={
            isExtraSmall ? { width: "100%" } : isSmall ? { width: "550px" } : {}
          }
        >
          <S.StyledWrapper
            style={
              isSmall
                ? {
                    flexFlow: "column",
                  }
                : {}
            }
          >
            <S.BodyColumns
              style={
                isSmall
                  ? {}
                  : { paddingRight: "10px", justifyContent: "space-between" }
              }
            >
              <S.InfoRow
                style={
                  isSmall
                    ? {
                        justifyContent: "center",
                      }
                    : { minWidth: "250px", justifyContent: "space-between" }
                }
              >
                <S.TitleColumn
                  style={isSmall ? { width: "150px", marginRight: "20%" } : {}}
                >
                  <S.Img src={carProps.image}></S.Img>
                </S.TitleColumn>
                <S.TitleColumn>
                  <S.RecentSearchTypography variant="CarName">
                    {carProps.brand}
                  </S.RecentSearchTypography>
                  <S.RecentSearchTypography variant="Subtitle">
                    {carProps.model}
                  </S.RecentSearchTypography>
                  <S.RefreshButton onClick={getRefreshedAgo}>
                    <S.RecentSearchTypography variant="Refresh">
                      Refresh
                    </S.RecentSearchTypography>
                    <img
                      src={RefreshIcon}
                      width="12"
                      height="12"
                      style={{ verticalAlign: "middle" }}
                    ></img>
                  </S.RefreshButton>
                  <S.RecentSearchTypography variant="Title">
                    {refreshDate}
                  </S.RecentSearchTypography>
                </S.TitleColumn>
              </S.InfoRow>
              <S.InfoRow>
                <S.StyledTooltip
                  onClose={handleTooltipOpen}
                  open={TooltipOpen}
                  disableFocusListener
                  title={
                    <S.SettingsColumn>
                      {/* {carProps.headunit !== "-" && (
                        <S.SearchRow>
                          <S.ServiceButton
                            onClick={handleHeadUnitCodingMethodOpen}
                          >
                            <S.ServiceImg
                              src={DownloadSettingsIcon}
                            ></S.ServiceImg>
                            <S.RecentSearchTypography variant="Settings">
                              Headunit Coding
                            </S.RecentSearchTypography>
                          </S.ServiceButton>
                        </S.SearchRow>
                      )} */}
                      <S.SearchRow>
                        <S.ServiceButton onClick={handleRepairPackOpen}>
                          <S.ServiceImg
                            src={DownloadSettingsIcon}
                          ></S.ServiceImg>
                          <S.RecentSearchTypography variant="Settings">
                            Repair Pack Download
                          </S.RecentSearchTypography>
                        </S.ServiceButton>
                      </S.SearchRow>
                      <S.SearchRow>
                        <S.ServiceButton onClick={handleCarHistoryOpen}>
                          <S.ServiceImg
                            src={HistorySettingsIcon}
                          ></S.ServiceImg>
                          <S.RecentSearchTypography variant="Settings">
                            Car History
                          </S.RecentSearchTypography>
                        </S.ServiceButton>
                      </S.SearchRow>
                      <S.SearchRow>
                        <S.ServiceButton onClick={handleRetrofitHeadUnitOpen}>
                          <S.ServiceImg
                            src={LevelUpSettingsIcon}
                          ></S.ServiceImg>
                          <S.RecentSearchTypography variant="Settings">
                            Retrofit Headunit
                          </S.RecentSearchTypography>
                        </S.ServiceButton>
                      </S.SearchRow>
                      {carProps.map !== "-" && (
                        <>
                          <S.SearchRow>
                            <S.ServiceButton onClick={handleMapCodeOpen}>
                              <S.ServiceImg
                                src={CodeBranchSettingsIcon}
                              ></S.ServiceImg>
                              <S.RecentSearchTypography variant="Settings">
                                Map Code
                              </S.RecentSearchTypography>
                            </S.ServiceButton>
                          </S.SearchRow>

                          <S.SearchRow>
                            <S.ServiceButton
                              onClick={handleLifeTimeMapCodeOpen}
                            >
                              <S.ServiceImg
                                src={MapSettingsIcon}
                              ></S.ServiceImg>
                              <S.RecentSearchTypography variant="Settings">
                                Lifetime Map Code
                              </S.RecentSearchTypography>
                            </S.ServiceButton>
                          </S.SearchRow>
                        </>
                      )}
                      {carProps.headunit !== "-" && (
                        <S.SearchRow>
                          <S.ServiceButton
                            onClick={handleHeadUnitActivationMethodOpen}
                          >
                            <S.ServiceImg
                              src={CheckSquareSettingsIcon}
                            ></S.ServiceImg>
                            <S.RecentSearchTypography variant="Settings">
                              Headunit Activation
                            </S.RecentSearchTypography>
                          </S.ServiceButton>
                        </S.SearchRow>
                      )}
                    </S.SettingsColumn>
                  }
                >
                  <S.ButtonService
                    style={
                      isSmall
                        ? {
                            width: "50%",
                            borderRadius: "0px",
                          }
                        : {}
                    }
                    onClick={handleTooltipOpen}
                  >
                    <img src={SettingCogIcon}></img>
                    <S.RecentSearchTypography variant="Service">
                      Choose a service
                    </S.RecentSearchTypography>
                  </S.ButtonService>
                </S.StyledTooltip>
                <S.ButtonSettings
                  style={
                    isSmall
                      ? {
                          width: "50%",
                          borderRadius: "0px",
                          marginLeft: "0px",
                        }
                      : {}
                  }
                  onClick={handleEquipmentOpen}
                  variant="outlined"
                >
                  <img src={SlidersIcon} width="15" height="15"></img>
                  <S.RecentSearchTypography variant="Settings">
                    Equipment
                  </S.RecentSearchTypography>
                </S.ButtonSettings>
              </S.InfoRow>
            </S.BodyColumns>

            <S.BodyColumns
              style={
                isSmall
                  ? {
                      paddingLeft: "20px",
                    }
                  : {}
              }
            >
              <S.SearchRow
                style={
                  isSmall
                    ? {
                        justifyContent: "space-between",
                      }
                    : {}
                }
              >
                <S.InfoColumn>
                  <S.RecentSearchTypography variant="Title">
                    Development code
                  </S.RecentSearchTypography>
                  <S.RecentSearchTypography variant="Info">
                    {carProps.development_code}
                  </S.RecentSearchTypography>
                </S.InfoColumn>

                <S.InfoColumn>
                  <S.RecentSearchTypography variant="Title">
                    Short VIN (7 digits)
                  </S.RecentSearchTypography>
                  <S.RecentSearchTypography variant="Info">
                    {carProps.vin_short}
                  </S.RecentSearchTypography>
                </S.InfoColumn>
                <S.InfoColumn>
                  <S.RecentSearchTypography variant="Title">
                    I-Level
                  </S.RecentSearchTypography>
                  <S.RecentSearchTypography variant="Info">
                    {carProps.i_level}
                  </S.RecentSearchTypography>
                </S.InfoColumn>
                <S.InfoColumn>
                  <S.RecentSearchTypography variant="Title">
                    Headunit
                  </S.RecentSearchTypography>
                  <S.RecentSearchTypography variant="Info">
                    {carProps.headunit}
                  </S.RecentSearchTypography>
                </S.InfoColumn>
                <S.InfoColumn>
                  <S.RecentSearchTypography variant="Title">
                    Installed map
                  </S.RecentSearchTypography>
                  <S.RecentSearchTypography variant="Info">
                    {carProps.map}
                  </S.RecentSearchTypography>
                </S.InfoColumn>
              </S.SearchRow>

              <Grow
                in={checked}
                {...(checked ? { timeout: 200 } : {})}
                style={
                  OpenExpand
                    ? { transformOrigin: "0 0 0" }
                    : { display: "none" }
                }
              >
                <S.SearchRow
                  style={
                    (OpenExpand ? {} : { display: "none" },
                    isSmall
                      ? {
                          justifyContent: "space-between",
                        }
                      : {})
                  }
                >
                  <S.InfoColumn>
                    <S.RecentSearchTypography variant="Title">
                      VIN (17 digits)
                    </S.RecentSearchTypography>
                    <S.RecentSearchTypography variant="Info">
                      {carProps.vin}
                    </S.RecentSearchTypography>
                  </S.InfoColumn>
                  <S.InfoColumn>
                    <S.RecentSearchTypography variant="Title">
                      Last mileage
                    </S.RecentSearchTypography>
                    <S.RecentSearchTypography variant="Info">
                      {carProps.last_milege}
                    </S.RecentSearchTypography>
                  </S.InfoColumn>
                  <S.InfoColumn>
                    <S.RecentSearchTypography variant="Title">
                      Production date
                    </S.RecentSearchTypography>
                    <S.RecentSearchTypography variant="Info">
                      {carProps.date}
                    </S.RecentSearchTypography>
                  </S.InfoColumn>
                  <S.InfoColumn>
                    <S.RecentSearchTypography variant="Title">
                      Engine
                    </S.RecentSearchTypography>
                    <S.RecentSearchTypography variant="Info">
                      {carProps.engine}
                    </S.RecentSearchTypography>
                  </S.InfoColumn>
                </S.SearchRow>
              </Grow>
            </S.BodyColumns>
            <S.BodyColumns
              style={{
                alignItems: "center",
                paddingRight: "10px",
                paddingBottom: "10px",
              }}
            >
              <IconButton
                onClick={handleExpandOpen}
                style={{
                  backgroundColor: "#c9d0db",
                  color: "#e6eaf1",
                }}
              >
                <ExpandMoreIcon
                  id="expandIcon"
                  style={
                    OpenExpand
                      ? {
                          transform: "rotate(180deg)",
                          transition: "transform 0.4s",
                        }
                      : {
                          transform: "rotate(0deg)",
                          transition: "transform 0.4s",
                        }
                  }
                />
              </IconButton>
            </S.BodyColumns>
          </S.StyledWrapper>
        </S.styledPaper>
      </ClickAwayListener>
    </>
  );
};
export default observer(RecentSearchComponent);
