import { React, useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import GlobalComponents from "./GlobalComponents";
import FAQ from "./pages/FAQ";
import Home from "./pages/Home";
import Invite from "./pages/Invite";
import Login from "./pages/Login";
import Pricing from "./pages/Pricing";
import Purchases from "./pages/Purchases";
import Reset from "./pages/Reset";
import Search from "./pages/Search";

import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Cookies from "js-cookie";
import { Provider } from "mobx-react";
import tawkTo from "tawkto-react";
import Snackbar from "./components/Snackbar";
import ContextApi from "./ContextApi";
import "./fonts/fontawesome.js";
import getCustomThemeStyles from "./GlobalThemes";
import Subscriptions from "./pages/Subscriptions";
import ScrollToTop from "./ScrollToTop";
import { store } from "./store/global-store";

function App() {
  const tawkToPropertyId = process.env.REACT_APP_TAWK_TO_PROPERTY_ID;
  const tawkToKey = process.env.REACT_APP_TAWK_TO_KEY;

  const [auth, setAuth] = useState(!!Cookies.get("access_token"));
  const [productProps, setProductProps] = useState(null);
  const [regionProps, setRegionProps] = useState(null);
  const [regionVersionProps, setRegionVersionProps] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const contextInfo = {
    auth,
    setAuth,
    productProps,
    setProductProps,
    isLoading,
    setLoading,
    regionProps,
    setRegionProps,
    regionVersionProps,
    setRegionVersionProps,
  };

  useEffect(() => {
    if (auth) {
      store.refresh();
      tawkTo(tawkToPropertyId, tawkToKey);
      getProducts();
      getRegions();
      getRegionsVersions();
    }
  }, [auth]);

  const getProducts = () => {
    const res = axios
      .get(process.env.REACT_APP_API_URL + "/products", {
        withCredentials: true,
      })
      .then((res) => {
        setProductProps(res.data);
        setLoading(false);
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
        setLoading(false);
      });
  };

  const getRegions = () => {
    const res = axios
      .get(process.env.REACT_APP_API_URL + "/map-regions", {
        withCredentials: true,
      })
      .then((res) => {
        setRegionProps(res.data);
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  const getRegionsVersions = () => {
    const res = axios
      .get(process.env.REACT_APP_API_URL + "/map-regions-versions", {
        withCredentials: true,
      })
      .then((res) => {
        setRegionVersionProps(res.data);
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  const theme = getCustomThemeStyles({});
  const Routes = () => {
    const PropsContext = useContext(ContextApi);
    return (
      <Switch>
        <ProtectedLogin
          path="/login"
          auth={PropsContext.auth}
          component={Login}
        />
        <ProtectedLogin
          path="/reset/:id"
          auth={PropsContext.auth}
          component={Reset}
        />

        <ProtectedRoutes
          path="/pricing"
          auth={PropsContext.auth}
          component={Pricing}
        />
        <ProtectedRoutes
          path="/subscriptions"
          auth={PropsContext.auth}
          component={Subscriptions}
        />
        <ProtectedRoutes
          path="/purchases"
          auth={PropsContext.auth}
          component={Purchases}
        />

        <ProtectedRoutes path="/faq" auth={PropsContext.auth} component={FAQ} />
        <ProtectedRoutes
          path="/invite"
          auth={PropsContext.auth}
          component={Invite}
        />
        <ProtectedRoutes
          path="/search"
          auth={PropsContext.auth}
          component={Search}
        />
        {localStorage.getItem("search_count") > 0 ? (
          <ProtectedRoutes
            path="/home"
            auth={PropsContext.auth}
            component={Search}
          />
        ) : (
          <ProtectedRoutes
            path="/home"
            auth={PropsContext.auth}
            component={Home}
          />
        )}

        {localStorage.getItem("search_count") > 0 ? (
          <ProtectedRoutes
            path="/"
            auth={PropsContext.auth}
            component={Search}
          />
        ) : (
          <ProtectedRoutes path="/" auth={PropsContext.auth} component={Home} />
        )}
      </Switch>
    );
  };

  const ProtectedRoutes = ({ auth, component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={() => (auth ? <Component /> : <Redirect to="/Login" />)}
      />
    );
  };

  const ProtectedLogin = ({ auth, component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={() => (!auth ? <Component /> : <Redirect to="/Home" />)}
      />
    );
  };

  return (
    <ContextApi.Provider value={contextInfo}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Snackbar />
          <Router>
            <ScrollToTop />
            <GlobalComponents />

            <Switch>
              <div>
                {auth && <Navbar />}

                <Routes />

                {auth && <Footer />}
              </div>
            </Switch>
          </Router>
        </Provider>
      </ThemeProvider>
    </ContextApi.Provider>
  );
}

export default Sentry.withProfiler(App);
