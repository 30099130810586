import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { StyledButton } from "../../../GlobalComponents";

export const SearchButton = withStyles((theme) => ({
  root: {
    minWidth: "100px",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "block",
    height: "50px",
  },
}))(StyledButton);

export const NavLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
`;

export const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d2d2d2",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputBase-root": {
      color: "black",
    },
    "& .MuiFilledInput ": {
      color: "gray",
    },

    "& fieldset": {},
    minWidth: "280px",
  },
}))(TextField);

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SearchColumn = styled.div`
  display: flex;
  flex-direction: Column;
`;
export const SearchTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    textAlign: "center",
  },
  body: {
    color: theme.palette.primary.dark,
    textAlign: "center",
  },
  search: {
    ...theme.typography.h5,

    textAlign: "center",
    fontWeight: 700,
  },
}))(Typography);
