export const testas = (name, services) => {
  return { activationMethodBody, services };
};

export const activationMethodBody = {
  bodyId: "activationMethodBody",
  bodyTitle: "Choose Activation Method",
  leftTitle: "Feature Code",
  rightTitle: "USB Pack",
  leftSide: [
    "Feature Installer Activation code",
    "Suitable for all Ilevels",
    "EU/USA/ASIA headunits",
  ],
  rightSide: [
    "Auto install .BIN file",
    "Only usable on iLevel < 18.11",
    "EU/Not for ASIA headunits",
  ],
  leftDialog: "HeadUnitActivation",
  rightDialog: "HeadUnitActivation",
};

export const codingMethodBody = {
  bodyId: "codingMethodBody",

  bodyTitle: "Choose Headunit",
  leftTitle: "Simple coding",
  rightTitle: "Advanced coding",
  leftSide: ["Features such as VIM,///M startupo logo"],
  rightSide: ["Features such as VO unlock, RTTI activation"],
  leftDialog: "HeadUnitChoose",
  rightDialog: "HeadUnitChoose",
};

export const simpleCodingBody = {
  bodyId: "simpleCodingBody",
  bodyTitle: "Choose Headunit",
  firstDialog: "HeadUnitActivation",
  secondDialog: "HeadUnitActivation",
  thirdDialog: "HeadUnitActivation",
};

export const retrofitHeadunitBody = {
  bodyId: "retrofitHeadunitBody",
  bodyTitle: "Choose Headunit",
  firstDialog: "HeadUnitActivation",
  secondDialog: "HeadUnitActivation",
  thirdDialog: "HeadUnitMethod",
};

export const advancedBodyCoding = {
  bodyId: "advancedBodyCoding",
  bodyTitle: "Choose Headunit",
  firstDialog: "HeadUnitActivation",
  secondDialog: "HeadUnitActivation",
  thirdDialog: "HeadUnitActivation",
};

export const featureCodeBody = {
  bodyId: "FeatureCode FullPack",
  bodyTitle: "Feature Code",
  title: "Feature Code Generate",

  disclaimer:
    "Warning! We do not provide any map data, only USB activation packs.",
  leftSide: [""],
  topDisclaimer: (
    <div style={{ fontSize: 12, textAlign: "left" }}>
      Notice: in order to install feature codes you should use{" "}
      <a
        style={{ fontSize: 12, textAlign: "left" }}
        href="https://mega.nz/#F!PQ8ihaSK!mifSYy5C2c3NdTb4D7tfiQ"
        rel="noreferrer"
      >
        FeatureInstaller
      </a>{" "}
      and have a Net cable.
    </div>
  ),
  rightSide: [""],
  picklisttype: ["package", "region", "steeringWheel"],
  packagepicklistvalue: [
    "ID5/6 Pack (includes map activation, Apple CarPlay and BMW Apps)",
    "ID5/6 VIM (includes ViM only)",
    "ID5/6 Full Screen CarPlay (only works when Apple CarPlay is already present)",
  ],

  regionpicklistvalue: [],
  specificCheckboxList: ["includeVim", "includeFullScreen", "vinChange"],
  steeringWheelpicklistvalue: ["Left", "Right"],
  textfieldList: [],
  checkboxList: [],
  changeVin: true,
};

export const usbPackBody = {
  bodyId: "NBT EVOID5 6 Full FSC USB Pack",
  bodyTitle: "USB .BIN Auto Install Pack Generate",
  disclaimer:
    "Warning! We do not provide any map data, only USB activation packs.",
  leftSide: [""],
  topDisclaimer: "Notice: Only usable on iLevel < 18.11 ",
  topDisclaimer2: ", not for ASIA headunits",
  rightSide: [""],
  picklisttype: ["region"],
  regionpicklistvalue: [],
  specificCheckboxList: ["includeVim", "includeFullScreen", "vinChange"],
  textfieldList: [],
  checkboxList: [],
  changeVin: true,
};

export const advancedHeadUnitBinPacksnbtnext = {
  bodyId: "NBT NEXT ADVANCED CODING",
  bodyTitle: "Headunit Bin Packs",
  price: 54.0,
  oldprice: 60.0,
  disclaimer:
    "Instructions \nCopy the provided BIN into USB, plug USB in the car, wait 30 seconds, remove USB and restart the Headunit\nSelected features will be installed automatically ",
  picklisttype: [""],
  specificCheckboxList: [],
  topDisclaimer: "Notice: Only usable on iLevel < 18.11",
  topDisclaimer2: ", not for ASIA headunits",
  checkboxList: [
    {
      title: "Clear existing SWT/FSCs",
      subtitle:
        "Deletes any existing SWT/FSCs so that head unit can be reloaded with SWT/FSC pack",
    },
    {
      title: "Enable telnet + SSH + SCP",
      subtitle: "Enable NBT network services including telnet, SSH and SCP",
    },
    {
      title: "Save current coding to usb",
      subtitle:
        "Generates a bin UPD to save the HU coding to a blank USB drive. Insert blank USB drive after removing UPD USB drive but before restarting head unit.",
    },
  ],
  textfieldList: [
    {
      title: "Change head unit HWEL",
      subtitle: "change head unit hardware element 1 - USE AT YOUR OWN RISK",
    },
    {
      title: "Change head unit serial number",
      subtitle: "change head unit serial number - USE AT YOUR OWN RISK",
    },
    {
      title: "Change head unit VIN",
      subtitle: "Change head unit VIN (enter long 17 characters VIN)",
    },
  ],
};
export const advancedHeadUnitBinPacksnbtEVO4 = {
  topDisclaimer: "Notice: Only usable on iLevel < 18.11",
  topDisclaimer2: ", not for ASIA headunits",

  bodyId: "NBT EVOID4 ADVANCED CODING",
  bodyTitle: "Headunit Bin Packs",
  price: 54.0,
  oldprice: 60.0,
  disclaimer:
    "Instructions \nCopy the provided BIN into USB, plug USB in the car, wait 30 seconds, remove USB and restart the Headunit\nSelected features will be installed automatically ",
  picklisttype: [""],
  checkboxList: [],
  specificCheckboxList: [],

  textfieldList: [],
};
export const advancedHeadUnitBinPacksnbtEVO56 = {
  topDisclaimer: "Notice: Only usable on iLevel < 18.11",
  topDisclaimer2: ", not for ASIA headunits",

  bodyId: "NBT EVOID5 6 ADVANCED CODING",
  bodyTitle: "Headunit Bin Packs",
  price: 54.0,
  oldprice: 60.0,
  disclaimer:
    "Instructions \nCopy the provided BIN into USB, plug USB in the car, wait 30 seconds, remove USB and restart the Headunit\nSelected features will be installed automatically ",
  picklisttype: [""],
  specificCheckboxList: [],

  checkboxList: [
    {
      title: "Clear existing SWT/FSCs",
      subtitle:
        "Deletes any existing SWT/FSCs so that head unit can be reloaded with SWT/FSC pack",
    },
    {
      title: "Enable Apple CarPlay full screen",
      subtitle: "Enable EVO full screen Apple CarPlay.",
    },
    {
      title: "Enable RTTI",
      subtitle: "Enable RTTI options. Requires ConnectedDrive online.",
    },
    {
      title: "Enable ViM",
      subtitle:
        "Enables EVO Video In Motion functionality - includes enabling video codecs & video from usb",
    },
    {
      title: "Fully virginize HU",
      subtitle:
        "Performs a full virginization of EVO head unit - deletes all SWT/FSC - resets VIN to all zero - clears V850 to allow flash of any bootloader version",
    },
    {
      title: "Partially virginize HU",
      subtitle:
        "Performs a partial virginization of EVO head unit - deletes all SWT/FSC - resets VIN to all zero",
    },
    {
      title: "Restore Apple CarPlay split screen",
      subtitle: "Restore EVO split screen Apple CarPlay.",
    },
    {
      title: "Save current coding to usb",
      subtitle:
        "Generates a bin UPD to save the HU coding to a blank USB drive. Insert blank USB drive after removing UPD USB drive but before restarting head unit.",
    },
    {
      title: "Save head unit details",
      subtitle:
        "Save head unit details including serial number, ethernet & BT mac addresses, VINs etc. Insert blank USB drive after removing UPD USB but before restarting HU.",
    },
    {
      title: "Save V850 adjust + flash + eeprom to usb",
      subtitle:
        "Save V850 adjust + flash + eeprom blocks to usb - insert blank usb after bin usb removal but before restarting HU",
    },
    {
      title: "Upgrade existing FSC install to CarPlay full screen",
      subtitle:
        "Upgrade an existing EVO FSC customer to full screen Apple CarPlay",
    },
    {
      title: "VO coding fix",
      subtitle:
        "VO coding fix bin for ID4/ID5 - use BIN when VO coding fails - load BIN each time VO coding is required. Insert USB, wait 30secs, remove USB, restart HU, then wait 60secs then VO code the HU.",
    },
    {
      title: "VO coding fix (permanent)",
      subtitle:
        "VO coding fix bin for ID4/ID5 - use BIN when VO coding fails - BIN installs itself so that it loads on every start. Insert USB, wait 30secs, remove USB, restart HU, then wait 60secs then VO code the HU.",
    },
  ],
  textfieldList: [
    {
      title: "Change head unit VIN",
      subtitle: "Change head unit VIN (enter long 17 characters VIN)",
    },
    {
      title: "Enable/disable inband ringing",
      subtitle: "Enable/disable inband ringing (1 - active, 0 - not active)",
    },
  ],
};

export const simpleHeadUnitBinPacksnbtnext = {
  topDisclaimer: "Notice: Only usable on iLevel < 18.11 ",
  topDisclaimer2: ", not for ASIA headunits",

  bodyId: "NBT NEXT CODING",
  bodyTitle: "Headunit Bin Packs",

  disclaimer:
    "Instructions \nCopy the provided BIN into USB, plug USB in the car, wait 30 seconds, remove USB and restart the Headunit\nSelected features will be installed automatically ",
  picklisttype: [""],
  specificCheckboxList: [],

  checkboxList: [
    {
      title: "Change radio to ECE freq",
      subtitle: "Changes radio to ECE frequency",
    },
    {
      title: "Disable add bluetooth speed lock",
      subtitle: "Disable speedlock for add bluetooth device function",
    },
    { title: "Disable disclaimer", subtitle: "Disable the disclaimer" },
    {
      title: "Enable ///M startup emblem",
      subtitle: "Changes the startup emblem to ///M",
    },
    {
      title: "Enable acoustic lock confirm",
      subtitle: "Enable acoustic lock confirm",
    },
    {
      title: "Enable BT 2 phones",
      subtitle: "Enable bluetooth 2 phones in parallel",
    },
    {
      title: "Enable enhanced bluetooth",
      subtitle: "Enable enhanced bluetooth (6NS)",
    },
    {
      title: "Enable external HiFi amplifier",
      subtitle: "Enable external HiFi amplifier (not connected to MOST)",
    },
    {
      title: "Enable full text message",
      subtitle: "Enable full display of text message",
    },
    {
      title: "Enable Harman-Kardon audio",
      subtitle: "Enable Harman-Kardon audio settings",
    },
    {
      title: "Enable video in motion",
      subtitle:
        "Activates video from USB, enables all video codecs & removes video speedlocks",
    },
    {
      title: "Sport display activation",
      subtitle: "Activates sport display",
    },
  ],
  textfieldList: [
    {
      title: "Enable alternate sound signal set",
      subtitle:
        "Enable alternative sound signal set (0 - BMW/ 1 -Mini/ 2 - RR/ 3 -BMW i)",
    },
    {
      title: "Enable/disable inband ringing",
      subtitle: "Enable/disable inband ringing (1 - active, 0 - not active)",
    },
  ],
};

export const simpleHeadUnitBinPacksnbtEVO4 = {
  topDisclaimer: "Notice: Only usable on iLevel < 18.11 ",
  topDisclaimer2: ", not for ASIA headunits",

  bodyId: "NBT EVOID4 CODING",
  bodyTitle: "Headunit Bin Packs",
  price: 54.0,
  oldprice: 60.0,
  disclaimer:
    "Instructions \nCopy the provided BIN into USB, plug USB in the car, wait 30 seconds, remove USB and restart the Headunit\nSelected features will be installed automatically ",
  picklisttype: [""],
  specificCheckboxList: [],

  checkboxList: [
    {
      title: "Enable enhanced bluetooth (ID4)",
      subtitle: "Enable enhanced bluetooth for ID4",
    },
  ],
  textfieldList: [],
};

export const simpleHeadUnitBinPacksnbtEVO56 = {
  topDisclaimer: "Notice: Only usable on iLevel < 18.11",
  topDisclaimer2: ", not for ASIA headunits",

  bodyId: "NBT EVOID4 CODING",
  bodyTitle: "Headunit Bin Packs",
  price: 54.0,
  oldprice: 60.0,
  disclaimer:
    "Instructions \nCopy the provided BIN into USB, plug USB in the car, wait 30 seconds, remove USB and restart the Headunit\nSelected features will be installed automatically ",
  picklisttype: [""],
  specificCheckboxList: [],

  checkboxList: [
    {
      title: "Change HMI Version to ID5_Plus_Plus",
      subtitle: "Change interface to ID5_Plus_Plus",
    },
    {
      title: "Change HMI Version to ID6_Lite",
      subtitle: "Change interface to ID6_Lite",
    },
    {
      title: "Change radio to ECE freq",
      subtitle: "Changes radio to ECE frequency",
    },
    {
      title: "Disable add bluetooth speed lock",
      subtitle: "Disable speedlock for add bluetooth device function",
    },
    {
      title: "Disable disclaimer",
      subtitle: "Disable the disclaimer",
    },
    {
      title: "Enable ///M startup emblem",
      subtitle: "Changes the startup emblem to ///M",
    },
    {
      title: "Enable acoustic lock confirm",
      subtitle: "Enable acoustic lock confirm",
    },
    {
      title: "Enable APIX 1 display",
      subtitle: "Enable support for APIX 1 display (1280x480)",
    },
    {
      title: "Enable APIX 2 10 inch display",
      subtitle:
        "Enable support for APIX 2 10.25 inch display (1440x540) + touch",
    },
    {
      title: "Enable Apple CarPlay",
      subtitle:
        "Enable Apple CarPlay functionality - NOTE: need to upload 0x143 FSC - either OEM or retrofit pack",
    },
    {
      title: "Enable Bang and Olufsen DSP",
      subtitle: "Enable Bang and Olufsen DSP settings",
    },
    {
      title: "Enable BT 2 phones",
      subtitle: "Enable bluetooth 2 phones in parallel",
    },
    {
      title: "Enable enhanced bluetooth (ID5)",
      subtitle: "Enable enhanced bluetooth for ID5/6",
    },
    {
      title: "Enable external GPS",
      subtitle: "Enable support for external GPS on ATM",
    },
    {
      title: "Enable external HiFi amplifier",
      subtitle: "Enable external HiFi amplifier (not connected to MOST)",
    },
    {
      title: "Enable full text message",
      subtitle: "Enable full display of text message",
    },
    {
      title: "Enable Harman-Kardon audio",
      subtitle: "Enable Harman-Kardon audio settings",
    },
    {
      title: "Enable internal GPS",
      subtitle: "Enable support for internal GPS",
    },
    {
      title: "Enable screen mirroring",
      subtitle: "Enable Android screen mirroring",
    },
    {
      title: "Enable ViM menu option",
      subtitle:
        "Enable ViM menu option & enable all codecs and video from USB. After applying UPD, push iDrive controller forward and hold, press Media then Menu then Media.",
    },
    {
      title: "Sport display activation",
      subtitle: "Activates sport display",
    },
    {
      title: "Sport display activation - M colours",
      subtitle: "Activates sport display with M colours",
    },
    {
      title: "Video activation",
      subtitle: "Activates video from USB & enables all video codecs",
    },
  ],
  textfieldList: [
    {
      title: "Enable alternate sound signal set",
      subtitle:
        "Enable alternative sound signal set (0 - BMW/ 1 -Mini/ 2 - RR/ 3 -BMW i)",
    },
    {
      title: "Enable/disable inband ringing",
      subtitle: "Enable/disable inband ringing (1 - active, 0 - not active)",
    },
  ],
};

export const cicActivationBody = {
  bodyId: "CIC Full FSC USB Pack",
  bodyTitle: "USB .BIN Auto Install Pack",
  price: 12.5,
  oldprice: 12.5,
  disclaimer:
    "Warning! We do not provide any map data, only USB activation packs.",
  leftSide: [""],
  rightSide: [""],

  picklisttype: ["region"],

  regionpicklistvalue: [],

  textfieldList: [],
  checkboxList: [],
  specificCheckboxList: [],
  changeVin: true,
};

export const mguActivationBody = {
  bodyId: "FeatureCode MGU",

  bodyTitle: "Feature Code Generate ",
  disclaimer:
    "Warning! We do not provide any map data, only Feature activation code.",
  leftSide: [""],
  rightSide: [""],
  picklisttype: ["package"],
  packagepicklistvalue: ["MGU VIM", "MGU VIM", "vinChange"],
  checkboxList: [],
  specificCheckboxList: [],
  changeVin: true,

  textfieldList: [],
};

export const repairPackBody = {
  bodyId: "Repair pack",
  bodyTitle: "Download Repair Pack",
  disclaimer: "",
  picklisttype: [""],
  specificCheckboxList: [],

  checkboxList: [
    {
      title: "I have full understanding how to use repair packs",
      subtitle: "",
    },
  ],
  textfieldList: [],
};

export const headunitnbtNext = {
  bodyId: "NBT NEXT Full FSC USB Pack",
  bodyTitle: "USB .BIN Auto Install Pack Generate",
  disclaimer:
    "Warning! We do not provide any map data, only USB activation packs.",

  picklisttype: ["region"],
  regionpicklistvalue: [],
  specificCheckboxList: ["includeVim", "vinChange"],
  textfieldList: [],
  checkboxList: [],
};

export const headunitnbtEVO4 = {
  bodyId: "NBT EVOID4 Full FSC USB Pack",
  bodyTitle: "USB .BIN Auto Install Pack Generate",
  disclaimer:
    "Warning! We do not provide any map data, only USB activation packs.",
  topDisclaimer: "Notice: Only usable on iLevel < 18.11",
  topDisclaimer2: ", not for ASIA headunits",

  picklisttype: ["region"],
  regionpicklistvalue: [],
  specificCheckboxList: ["includeVim", "vinChange"],
  textfieldList: [],
  checkboxList: [],
};

export const carHistoryBody = {
  bodyId: "Car history",
  bodyTitle: "Download Car History",

  disclaimer: "",
  picklisttype: [""],
  specificCheckboxList: [],

  checkboxList: [],
  textfieldList: [],
};

export const mapCode = {
  bodyId: "Map Code",
  bodyTitle: "Download FSC Map Activation Code",
  disclaimer:
    "Warning! We do not provide any map data, only FSC activation code.",
  picklisttype: ["region", "mapVersion"],
  regionpicklistvalue: [],
  mapVersionPicklistValue: [],

  specificCheckboxList: [],
  textfieldList: [],
  checkboxList: [],
};

export const ID4mapCode = {
  bodyId: "ID4 Mapcode",
  bodyTitle: "Download FSC Map Activation Code",
  disclaimer:
    "Warning! We do not provide any map data, only FSC activation code.",
  picklisttype: ["region", "mapVersion"],
  regionpicklistvalue: [],
  mapVersionPicklistValue: [],

  specificCheckboxList: [],
  textfieldList: [],
  checkboxList: [],
};

export const routemapCode = {
  bodyId: "Route Mapcode",
  bodyTitle: "Download FSC Map Activation Code",
  price: 54.0,
  oldprice: 60.0,
  disclaimer:
    "Warning! We do not provide any map data, only FSC activation code.",
  picklisttype: ["region", "mapVersion"],
  regionpicklistvalue: [],
  mapVersionPicklistValue: [],

  specificCheckboxList: [],
  textfieldList: [],
  checkboxList: [],
};

export const mapCodeLifetime = {
  bodyId: "Map Code Lifetime",
  bodyTitle: "Download Lifetime FSC Map Activation Code",
  disclaimer:
    "Warning! We do not provide any map data, only FSC activation code.",
  picklisttype: ["region"],
  regionpicklistvalue: [],
  specificCheckboxList: [],
  textfieldList: [],
  checkboxList: [],
};
export const ID4mapCodeLifetime = {
  bodyId: "ID4 Mapcode Lifetime",
  bodyTitle: "Download Lifetime FSC Map Activation Code",
  disclaimer:
    "Warning! We do not provide any map data, only FSC activation code.",
  picklisttype: ["region"],
  regionpicklistvalue: [],
  specificCheckboxList: [],
  textfieldList: [],
  checkboxList: [],
};
export const routemapCodelifetime = {
  bodyId: "Route Mapcode lifetime",
  bodyTitle: "Download Lifetime FSC Map Activation Code",
  disclaimer:
    "Warning! We do not provide any map data, only FSC activation code.",
  picklisttype: ["region"],
  regionpicklistvalue: [],
  specificCheckboxList: [],
  textfieldList: [],
  checkboxList: [],
};
