import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { StyledContainer } from "../../GlobalComponents";

export const Container = styled(StyledContainer)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 50px;
  max-width: 1200px;
`;

export const LeftContainer = styled.div`
  flex-direction: column;
  padding: 20px;
  border: solid 0.5px #d1d8e3;
`;

export const RightContainer = styled.div`
  flex-direction: column;
  padding: 20px;
  border: solid 0.5px #d1d8e3;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const StyledBadgePeople = withStyles((theme) => ({
  badge: {
    top: 10,
    right: -10,
  },
}))(Badge);

export const StyledBadgeCoin = withStyles((theme) => ({
  badge: {
    top: 10,
    right: -10,
  },
}))(Badge);
