import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";

export const StyledDialog = withStyles((theme) => ({
  root: {},
}))(Dialog);

export const BodyWrapper = styled.div`
  background-color: white;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 30px 20px 30px 20px;
  justify-content: center;

  background-image: linear-gradient(to right, #1f2441 0%, #027bfe 100%);
`;

export const InfoRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-flow: Column;
  align-items: center;
  justify-content: center;
  min-width: 200px;
`;

export const HeadUnitMethodTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  Title: {
    ...theme.typography.h4,
    color: theme.palette.primary.light,

    fontWeight: 700,
  },
  BodyTitle: {
    ...theme.typography.h6,
    fontWeight: 700,
  },
  Body: {
    ...theme.typography.subtitle1,

    color: theme.palette.primary.darkBlue,
  },
}))(Typography);

export const StyledButtton = withStyles((theme) => ({
  root: {
    padding: "20px",
    backgroundColor: "#eef0f3",
    minWidth: "260px",
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: `${darken("#eef0f3", 0.2)}`,
    },
  },
}))(Button);
