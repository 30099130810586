import * as S from "./Loading.styled";
import { StyledTypography } from "../../../GlobalComponents";

const Loading = () => {
  return (
    <S.Container>
      <StyledTypography variant="h4">Loading... </StyledTypography>
    </S.Container>
  );
};

export default Loading;
