import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { StyledContainer } from "../../GlobalComponents";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Container = styled(StyledContainer)``;

export const CheckIcon = styled(FontAwesomeIcon)`
  margin-top: 6px;
  margin-right: 6px;
  color: #3c88cb;
`;

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubscriptionsTypography = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  h3: {
    fontWeight: 700,
    marginBottom: theme.spacing(5),
  },
  subscriptionBody: {
    ...theme.typography.h6,
    fontWeight: 700,
  },
  mainTitle: {
    ...theme.typography.h4,
    color: "red",
  },
  carTitle: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.palette.primary.darkBlue,
  },
  priceTitle: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.darkBlue,
  },
  price: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(1),
  },
  oldPrice: {
    ...theme.typography.h5,
    textDecoration: "line-through",
  },
  discountedPrice: {
    ...theme.typography.h4,
    color: theme.palette.primary.main,
  },

  popular: {
    ...theme.typography.h6,
    color: theme.palette.error.main,
    fontWeight: 700,
  },
}))(Typography);

export const Wrapper = styled.div`
  max-width: 1600px;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const PackageWrapper = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  text-align: center;
  border-radius: 8px;
  border: solid 0.5px #e5e5e5;
  font-weight: 700;
  font-size: 20px;
`;

export const CustomPackageContainer = styled.div`
  margin-bottom: 10px;

  border-radius: 8px;
  border: solid 0.5px #e5e5e5;
  padding: 20px;
`;

export const ExactPackageContainer = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-radius: 8px;
  border: solid 0.5px #e5e5e5;
`;

export const CurrentSubscriptionContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  border: solid 0.5px #e5e5e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SubscriptionContainer = styled.div`
  padding: 30px;
  border-radius: 8px;
  border: solid 0.5px #e5e5e5;
`;

export const CreditColumns = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 50px;
`;

export const ExactCreditColumns = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
`;

export const CreditRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledExactButtton = withStyles((theme) => ({
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#1dbf59",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    color: "white",
    "&:hover": {
      backgroundColor: `${darken("#1dbf59", 0.2)}`,
    },
  },
}))(Button);

export const StyledButtton = withStyles((theme) => ({
  root: {
    backgroundColor: "#1dbf59",
    borderRadius: 3,
    minWidth: "280px",
    color: "white",
    "&:hover": {
      backgroundColor: `${darken("#1dbf59", 0.2)}`,
    },
  },
}))(Button);

export const SubscriptionImage = styled.img`
  width: 120px;
`;

export const ContainerWrapper = styled.img`
  position: relative;
  width: 160%;
  left: -20%;
  top: -20%;
  max-width: 300px;
`;

export const Ellipse = styled.div`
  width: 50%;
  height: 0;
  padding-bottom: 50%;
  border-radius: 50%;
`;

export const Ellipse2 = styled.div`
  position: relative;
  width: 80%;
  left: 10%;
  margin-top: 10%;
  height: 0;
  padding-bottom: 80%;
  border-radius: 50%;
`;

export const EllipsePopular = styled.div`
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 14px;
  background-color: #ffc4c4;
`;
