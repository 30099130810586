import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

export const StyledDialog = withStyles((theme) => ({
  root: {},
}))(Dialog);

export const StyledDialogTitle = withStyles((theme) => ({
  root: {
    backgroundimage: theme.palette.primary.dark,
  },
}))(DialogTitle);

export const Img = styled.img`
width:80%; height 80%;
background-color:white;

`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 30px;
  background-image: linear-gradient(to right, #1f2441 0%, #027bfe 100%);
`;

export const TitleColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: middle;
  justify-content: center;
`;

export const InfoColumn = styled.div`
  border: solid 1px rgba(225, 225, 225, 0.5);
  padding: 15px;
  display: flex;
  flex-flow: column;
  align-items: start;
  justify-content: start;
`;

export const TitleRow = styled.div`
  display: flex;

  flex-flow: row;
`;
export const InfoRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: start;
  justify-content: start;
`;

export const EquipmentTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    textAlign: "center",
  },
  Title: {
    ...theme.typography.subtitle1,
    textAlign: "left",
  },
  Subtitle: {
    ...theme.typography.h6,
    textAlign: "left",
    fontWeight: 700,
  },
  Body: {
    ...theme.typography.h6,
    textAlign: "left",
    color: theme.palette.primary.darkBlue,
  },
  BodyTitle: {
    ...theme.typography.h5,
    textAlign: "left",
    fontWeight: 700,
    color: theme.palette.primary.darkBlue,
  },
}))(Typography);
