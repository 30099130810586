import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import { StyledContainer } from "../../GlobalComponents";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

export const Container = styled(StyledContainer)`
  padding: 150px 20px 400px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1200px;
`;

export const Image = styled.img`
  vertical-align: bottom;
  transform: scale(0.5);
`;

export const HeaderTitle = styled.div`
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
`;

export const DialogBox = styled.div`
  padding: 20px;
  justify-content: center;
  display: flex;
`;

export const StyledTableHeaderCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.darkBlue,
  },
}))(TableCell);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    height: "100px",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#E2E2E2",
  },
}))(TableRow);

export const StyledTable = withStyles((theme) => ({
  root: {
    //overflow: "hidden",
  },
}))(Table);

export const RowContainer = styled.div``;

export const StyledButton = withStyles((theme) => ({
  root: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "30px",

    backgroundColor: "#1dbf59",

    "&:hover": {
      backgroundColor: `${darken("#1dbf59", 0.2)}`,
    },
  },
}))(Button);

export const PurchasesTypography = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  Title: {
    ...theme.typography.h4,
    fontWeight: 700,
    lineHeight: "1.35",
  },
  RowTitle: {
    ...theme.typography.h6,
    color: theme.palette.primary.light,
  },
  Row: {
    ...theme.typography.h6,
    fontWeight: 700,
  },
}))(Typography);
