import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { StyledContainer } from "../../../GlobalComponents";
import Typography from "@material-ui/core/Typography";

export const Container = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: url(https://i.imgur.com/ltLZd5H.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: calc(100vh);
`;

export const StyledPaper = styled.div`
  width: 300;
  height: 270;
  borderradius: theme.spacing(1);
  margin: theme.spacing(1, 2);
  flex-direction: column;
  filter: blur(0px);
  background-color: #1f2441;
  padding: 40px;
  border-radius: 16px;
`;

export const InfoRows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LoadingSearchTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    textAlign: "center",
  },
  collect: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
  },
  waiting: {
    ...theme.typography.h5,
    marginBottom: theme.spacing(2),
  },
  vin: {
    ...theme.typography.h5,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  popular: {
    ...theme.typography.h6,
    color: theme.palette.error.main,
    fontWeight: 700,
  },
}))(Typography);
