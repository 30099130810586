import { useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import { React, useContext, useEffect, useState } from "react";
import ContextApi from "../../ContextApi";
import { StyledTypography } from "../../GlobalComponents";
import Loading from "../GlobalComponents/Loading";
import * as S from "./Pricing.styled";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
  },
}));

const Pricing = () => {
  const PropsContext = useContext(ContextApi);

  const [products, setProducts] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (PropsContext.auth && !PropsContext.isLoading) {
      setProducts(
        PropsContext.productProps.services.concat(
          PropsContext.productProps.subscription
        )
      );
      setLoading(false);
    }
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  if (isLoading) {
    return <Loading />;
  }
  return (
    <S.Container>
      <S.Wrapper>
        <S.HeaderTitle>
          <StyledTypography variant="h5">Pricing</StyledTypography>
        </S.HeaderTitle>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <S.StyledTableHeaderCell>
                  <StyledTypography variant="h4">Service Name</StyledTypography>
                </S.StyledTableHeaderCell>
                <S.StyledTableHeaderCell>
                  <StyledTypography variant="h4">Price</StyledTypography>
                </S.StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <>
                  {product.active && (
                    <S.StyledTableRow>
                      <S.StyledTableCell>
                        <StyledTypography variant="h4">
                          {product.title}
                        </StyledTypography>
                      </S.StyledTableCell>
                      <S.StyledTableCell>
                        {product.promo_fiat || product.promo_credit ? (
                          <S.StyledBadge
                            badgeContent={
                              <S.StyledDiscoutBadge>
                                <S.PricingTypography variant="OldPrice">
                                  $
                                  {product.price_fiat
                                    ? parseFloat(product.price_fiat).toFixed(2)
                                    : parseFloat(product.price_credit).toFixed(
                                        2
                                      )}
                                </S.PricingTypography>
                              </S.StyledDiscoutBadge>
                            }
                          >
                            <S.PricingTypography variant="salePrice">
                              ${" "}
                              {product.promo_fiat
                                ? parseFloat(product.promo_fiat).toFixed(2)
                                : parseFloat(product.promo_credit).toFixed(2)}
                            </S.PricingTypography>
                          </S.StyledBadge>
                        ) : (
                          <S.PricingTypography variant="Price">
                            ${" "}
                            {product.price_fiat
                              ? parseFloat(product.price_fiat).toFixed(2)
                              : parseFloat(product.price_credit).toFixed(2)}
                          </S.PricingTypography>
                        )}
                      </S.StyledTableCell>
                    </S.StyledTableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </S.Wrapper>
    </S.Container>
  );
};

export default Pricing;
