import styled from "styled-components";

export const FooterContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #1f2441;
  display: flex;
  flex-direction: column;
  align-items: center;
  hr {
    opacity: 0.1;
    width: 100%;
  }
  overflow: hidden;
`;

export const FooterInformationWrapper = styled.div`
  max-width: 1440px;
  padding-left: 20px;
  padding-right: 20px;

  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;
