import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const NavContainer = styled.nav`
  background: #1f2441;
  z-index: 999;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

export const NavItems = styled.div`
  min-height: 70px;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  max-width: 1440px;
  margin: auto;
`;

export const NavLinks = styled.div`
  display: flex;
  flex: auto;
  width: 100%;
`;

export const NavInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
`;

export const NavLogin = styled.div`
  text-decoration: none;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
`;

export const NavItem = styled.div`
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background: ${darken("#0467FB", 0.8)};
  }
`;

export const NavbarTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
  },
  Logo: {
    ...theme.typography.h4,
    fontWeight: 600,
  },
  Links: {
    ...theme.typography.h6,
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.light,
    fontWeight: 600,
  },
  Credits: {
    ...theme.typography.h6,
    color: theme.palette.primary.light,
    paddingRight: theme.spacing(1),
  },
  Name: {
    ...theme.typography.h6,
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
}))(Typography);
