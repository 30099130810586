import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContextApi from "../../ContextApi";
import CoinIcon from "../../images/CoinIcon.png";
import DownloadIcon from "../../images/DownloadIcon.png";
import ViewIcon from "../../images/ViewIcon.png";
import useStore from "../../utils/useStore";
import Loading from "../GlobalComponents/Loading";
import * as S from "./Purchases.styled";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "10px 10px",
  },
  table: {
    minWidth: 650,
  },
}));

const Purchases = () => {
  const [purchases, setPurchases] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const PropsContext = useContext(ContextApi);
  const classes = useStyles();
  const { store } = useStore();

  useEffect(() => {
    if (!PropsContext.isLoading) {
      getProducts();
    }
  }, [PropsContext.productProps]);

  const getProducts = () => {
    const res = axios
      .get(process.env.REACT_APP_API_URL + "/purchases", {
        withCredentials: true,
      })
      .then((res) => {
        setPurchases(res.data);
        setLoading(false);
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <S.Container>
        <S.Wrapper>
          <S.HeaderTitle>
            <S.PurchasesTypography variant="Title">
              Purchases
            </S.PurchasesTypography>
          </S.HeaderTitle>
          <Paper style={{ overflow: "auto" }} className={classes.root}>
            <S.StyledTable>
              <TableHead>
                <TableRow>
                  <S.StyledTableHeaderCell>
                    <S.PurchasesTypography variant="RowTitle">
                      Service
                    </S.PurchasesTypography>
                  </S.StyledTableHeaderCell>
                  <S.StyledTableHeaderCell>
                    <S.PurchasesTypography variant="RowTitle">
                      VIN number
                    </S.PurchasesTypography>
                  </S.StyledTableHeaderCell>
                  <S.StyledTableHeaderCell>
                    <S.PurchasesTypography variant="RowTitle">
                      Date of Purchase
                    </S.PurchasesTypography>
                  </S.StyledTableHeaderCell>
                  <S.StyledTableHeaderCell>
                    <S.PurchasesTypography variant="RowTitle">
                      Price
                    </S.PurchasesTypography>
                  </S.StyledTableHeaderCell>
                  <S.StyledTableHeaderCell>
                    <S.PurchasesTypography variant="RowTitle"></S.PurchasesTypography>
                  </S.StyledTableHeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {purchases.map((purchase) => (
                  <S.StyledTableRow>
                    <S.StyledTableCell>
                      <S.PurchasesTypography variant="Row">
                        {purchase.id === 1
                          ? purchase.price === 1
                            ? purchase.price + " credit"
                            : purchase.price + " credits"
                          : purchase.service}
                      </S.PurchasesTypography>
                    </S.StyledTableCell>
                    <S.StyledTableCell>
                      <S.PurchasesTypography variant="Row">
                        {purchase.vin}
                      </S.PurchasesTypography>
                    </S.StyledTableCell>
                    <S.StyledTableCell>
                      <S.PurchasesTypography variant="Row">
                        {new Date(purchase.created_at).toLocaleDateString()}{" "}
                        {new Date(purchase.created_at).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </S.PurchasesTypography>
                    </S.StyledTableCell>
                    <S.StyledTableCell>
                      <S.PurchasesTypography
                        variant="Row"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "0px",
                        }}
                      >
                        <S.Image src={CoinIcon} />
                        {purchase.price}
                      </S.PurchasesTypography>
                    </S.StyledTableCell>
                    <S.StyledTableCell
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {purchase.file && purchase.file.includes("files") ? (
                        <S.StyledButton
                          onClick={() => {
                            window.open(
                              process.env.REACT_APP_API_URL + purchase.file
                            );
                          }}
                        >
                          <S.Image
                            src={DownloadIcon}
                            style={{
                              paddingRight: "5px",
                              paddingLeft: "5px",
                            }}
                          />
                          Download
                        </S.StyledButton>
                      ) : purchase.file ? (
                        <CopyToClipboard text={purchase.file}>
                          <S.StyledButton
                            onClick={() =>
                              store.callToast("Copied!", "success")
                            }
                          >
                            <S.Image src={ViewIcon} />
                            <div> {purchase.file}</div>
                          </S.StyledButton>
                        </CopyToClipboard>
                      ) : (
                        <> </>
                      )}
                    </S.StyledTableCell>
                  </S.StyledTableRow>
                ))}
              </TableBody>
            </S.StyledTable>
          </Paper>
        </S.Wrapper>
      </S.Container>
    </>
  );
};
export default observer(Purchases);
