const getThemeType = () => {
  if (["yes", "no"].includes(localStorage.getItem("isDarkMode"))) {
    return localStorage.getItem("isDarkMode") === "yes" ? "dark" : "light";
  }

  const matchMediaResult =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return matchMediaResult ? "dark" : "light";
};

export default getThemeType;
