import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import Signin from "./Signin";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import { useTheme } from "@material-ui/core/styles";
import BavarianVIN from "../../images/BavarianVIN.png";
import {
  StyledButton,
  StyledTypography,
  InputTextField,
} from "../../GlobalComponents";

import * as S from "./Login.styled.js";

function Login() {
  const theme = useTheme();

  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);
  const [isForgotPasswordDialogOpen, setForgotPasswordDialogOpen] =
    useState(false);

  return (
    <>
      <Signin
        isOpen={isLoginDialogOpen}
        setIsOpen={setIsLoginDialogOpen}
        signUp={{
          setIsOpen: setIsSignUpDialogOpen,
        }}
        forgotPassword={{
          setIsOpen: setForgotPasswordDialogOpen,
        }}
      />

      <SignUp
        isOpen={isSignUpDialogOpen}
        setIsOpen={setIsSignUpDialogOpen}
        login={{
          setIsOpen: setIsLoginDialogOpen,
        }}
      />

      <ForgotPassword
        isOpen={isForgotPasswordDialogOpen}
        setIsOpen={setForgotPasswordDialogOpen}
        login={{
          setIsOpen: setIsLoginDialogOpen,
        }}
      />
      <S.Container>
        <S.Img src={BavarianVIN} alt="Logo" width={"30%"}></S.Img>
        <StyledButton
          style={{ minWidth: "300px" }}
          size="large"
          onClick={() => setIsLoginDialogOpen(true)}
        >
          Log In
        </StyledButton>
        <Typography
          variant="subtitle1"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            color: theme.palette.primary.light,
            marginTop: theme.spacing(4),
          }}
        >
          BavarianVIN.com © All rights reserved {new Date().getFullYear()}
        </Typography>
      </S.Container>
    </>
  );
}

export default Login;
