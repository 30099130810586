import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  hr {
    opacity: 0.1;
    width: 100%;
  }
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
`;
export const SearchRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SearchColumn = styled.div`
  display: flex;
  flex-direction: Column;
`;

export const SearchTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  Title: {
    ...theme.typography.h4,
    fontWeight: 700,
  },
  Subtitle: {
    ...theme.typography.h5,
  },
}))(Typography);
