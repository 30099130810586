import * as S from "./LoadingCircle.styled";
import CircularProgress from "@mui/material/CircularProgress";
const Loading = () => {
  return (
    <S.Container>
      <CircularProgress />
    </S.Container>
  );
};

export default Loading;
