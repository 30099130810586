import { React } from "react";
import nbtEvoID4Icon from "../../../../images/nbtEvoID4Icon.png";
import nbtEvoID56Icon from "../../../../images/nbtEvoID56Icon.png";

import nbtNextIcon from "../../../../images/nbtNextIcon.png";

import * as S from "./HeadUnitChoose.styled";
import { useTheme, useMediaQuery } from "@material-ui/core";

const HeadUnitChoose = ({
  isOpen,
  setIsOpen,
  body,
  carProps,
  nextModal,
  nbtnextModal,
  nbtevo4Modal,
  nbtevo56Modal,
}) => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.StyledDialog
      maxWidth="md"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, background: "transparent" },
      }}
    >
      <S.TitleWrapper style={isSmall ? { flexFlow: "column" } : {}}>
        <S.HeadUnitMethodTypography variant="Title">
          {body.bodyTitle}
        </S.HeadUnitMethodTypography>
      </S.TitleWrapper>
      <S.BodyWrapper
        style={isSmall ? { padding: "10px" } : { padding: "30px" }}
      >
        <S.InfoRow
          style={isSmall ? { flexFlow: "column", alignItems: "center" } : {}}
        >
          {(body.bodyId !== "retrofitHeadunitBody" ||
            (carProps.headunit !== "NBT" &&
              body.bodyId === "retrofitHeadunitBody")) && (
            <S.StyledButtton
              onClick={() => {
                nextModal.setNextDialog(body.firstDialog);
                nextModal.setNextMethodBody(nbtnextModal);
              }}
              variant="contained"
              style={
                isSmall
                  ? { padding: "10px" }
                  : { marginRight: theme.spacing(1) }
              }
            >
              <S.InfoColumn>
                <img
                  src={nbtNextIcon}
                  width="100"
                  height="60"
                  style={{ verticalAlign: "middle" }}
                ></img>
              </S.InfoColumn>
            </S.StyledButtton>
          )}
          {(body.bodyId !== "retrofitHeadunitBody" ||
            (carProps.headunit !== "NBT EVO ID4" &&
              body.bodyId === "retrofitHeadunitBody")) &&
            nbtevo4Modal.bodyId !== "NBT EVOID4 ADVANCED CODING" && (
              <S.StyledButtton
                onClick={() => {
                  nextModal.setNextDialog(body.secondDialog);
                  nextModal.setNextMethodBody(nbtevo4Modal);
                }}
                variant="contained"
                style={
                  isSmall
                    ? { marginTop: theme.spacing(1), padding: "10px" }
                    : { marginLeft: theme.spacing(1) }
                }
              >
                <S.InfoColumn>
                  <img
                    src={nbtEvoID4Icon}
                    width="100"
                    height="60"
                    style={{ verticalAlign: "middle" }}
                  ></img>
                </S.InfoColumn>
              </S.StyledButtton>
            )}
          {(body.bodyId !== "retrofitHeadunitBody" ||
            (carProps.headunit !== "NBT EVO ID5/6" &&
              body.bodyId === "retrofitHeadunitBody")) && (
            <S.StyledButtton
              onClick={() => {
                nextModal.setNextDialog(body.thirdDialog);
                nextModal.setNextMethodBody(nbtevo56Modal);
              }}
              variant="contained"
              style={
                isSmall
                  ? { marginTop: theme.spacing(1), padding: "10px" }
                  : { marginLeft: theme.spacing(1) }
              }
            >
              <S.InfoColumn>
                <img
                  src={nbtEvoID56Icon}
                  width="100"
                  height="60"
                  style={{ verticalAlign: "middle" }}
                ></img>
              </S.InfoColumn>
            </S.StyledButtton>
          )}
        </S.InfoRow>
      </S.BodyWrapper>
    </S.StyledDialog>
  );
};
export default HeadUnitChoose;
