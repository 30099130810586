import { StyledContainer } from "../../GlobalComponents";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: url(https://i.imgur.com/ltLZd5H.jpg) no-repeat center center fixed;

  background-size: cover;
  min-height: calc(100vh);
`;

export const Img = styled.img`
  display: block;
`;
