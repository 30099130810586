import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";

export const Wrapper = styled.div`
  padding-bottom: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;
export const InfoRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const SearchRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-top: 8px;

  justify-content: start;
`;

export const ImgColumn = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const SearchColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: center;
`;

export const InfoColumn = styled.div`
  width: 250px;

  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: center;
`;

export const RefreshContainer = styled.div`
  border-radius: 16px;
  border: solid 0.5px #b4d4ff;
  background-color: #dfeeff;
  padding-right: 15px;

  padding-left: 5px;
  text-align: center;
`;

export const Img = styled.img``;
export const RecentSearchTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  Service: {
    ...theme.typography.subtitle1,
    marginLeft: theme.spacing(1),
    fontWeight: 700,
    color: theme.palette.primary.light,
  },
  Settings: {
    ...theme.typography.subtitle1,
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  CarName: {
    ...theme.typography.h4,
    fontWeight: 700,
  },
  Refresh: {
    ...theme.typography.subtitle1,

    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),

    color: "#01337e",
  },
  Title: {
    ...theme.typography.subtitle1,
    textAlign: "left",
  },
  Info: {
    ...theme.typography.h6,
    fontWeight: 700,
    textAlign: "left",
  },
  Subtitle: {
    ...theme.typography.h6,
    textAlign: "left",
    color: "#ababab",
  },
  Date: {
    ...theme.typography.subtitle2,
    textAlign: "left",
    color: "#ababab",
  },
  SearchTitle: {
    ...theme.typography.h4,
    marginTop: theme.spacing(2),

    fontWeight: 700,
    lineHeight: "1.35",
  },
}))(Typography);

export const ButtonSettings = withStyles((theme) => ({
  root: {
    maxHeight: "30px",
    paddingRight: "10px",
    maxWidth: "120px",
    borderRadius: "16px",
    border: "solid 0.5px #e2e2e2",

    backgroundColor: "#f1f1f1",

    "&:hover": {
      backgroundColor: `${darken(theme.palette.primary.light, 0.2)}`,
    },
  },
}))(Button);

export const RefreshButton = withStyles((theme) => ({
  root: {
    maxHeight: "30px",
    borderRadius: "16px",
    paddingRight: "10px",
    border: "solid 0.5px #b4d4ff",
    backgroundColor: "#dfeeff",
    marginLeft: "5px",
    zIndex: 600,
    "&:hover": {
      backgroundColor: `${darken("#dfeeff", 0.2)}`,
    },
  },
}))(Button);

export const ServiceButton = withStyles((theme) => ({
  root: {
    maxHeight: "30px",
    borderRadius: "16px",
    paddingRight: "10px",
    border: "solid 0.5px #b4d4ff",
    backgroundColor: "#dfeeff",
    marginLeft: "5px",
    zIndex: 600,
    "&:hover": {
      backgroundColor: `${darken("#dfeeff", 0.2)}`,
    },
  },
}))(Button);
