import styled from "styled-components";
import { Link } from "react-router-dom";
import { Drawer, ListItem } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

export const MenuListItem = styled(ListItem)``;

export const LinkItem = styled(Link)`
  text-decoration: None;
`;

export const DrawerTypography = withStyles((theme) => ({
  h5: {
    color: theme.palette.primary.light,
    textDecoration: "none",
  },
}))(Typography);

export const StyledDrawer = withStyles((theme) => ({
  paper: {
    background: theme.palette.primary.darkBlue,
    width: "250px",
  },
}))(Drawer);

export const List = styled.div`
  margin-top: 20px;
`;
