import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { StyledContainer } from "../../GlobalComponents";

export const Container = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  background: url(https://i.imgur.com/ltLZd5H.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: calc(100vh);
`;

export const resetContainer = styled.div`
  background: #1f2441;
  padding: 30px;
  border-radius: 8px;
`;

export const StyledDialog = withStyles((theme) => ({
  root: {
    Color: theme.palette.primary.light,
  },
}))(Dialog);
