import { useMediaQuery, useTheme } from "@material-ui/core";
import { React } from "react";
import * as S from "./HeadUnitMethod.styled";

const Equipment = ({
  isOpen,
  setIsOpen,
  body,
  nextModal,
  carProps,
  leftModal,
  rightModal,
}) => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.StyledDialog
      maxWidth="md"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, background: "transparent" },
      }}
    >
      <S.TitleWrapper style={isSmall ? { flexFlow: "column" } : {}}>
        <S.HeadUnitMethodTypography variant="Title">
          {body.bodyTitle}
        </S.HeadUnitMethodTypography>
      </S.TitleWrapper>
      <S.BodyWrapper
        style={isSmall ? { padding: "10px" } : { padding: "30px" }}
      >
        <S.InfoRow
          style={isSmall ? { flexFlow: "column", alignItems: "center" } : {}}
        >
          <S.StyledButtton
            onClick={() => {
              nextModal.setNextDialog(body.leftDialog);
              nextModal.setNextMethodBody(leftModal);
            }}
            variant="contained"
            style={
              isSmall
                ? { marginBottom: theme.spacing(1), padding: "10px" }
                : { marginRight: theme.spacing(1) }
            }
          >
            <S.InfoColumn>
              <S.HeadUnitMethodTypography variant="BodyTitle">
                {body.leftTitle}
              </S.HeadUnitMethodTypography>
              {body.leftSide.map((name) => (
                <S.HeadUnitMethodTypography variant="Body">
                  {name}
                </S.HeadUnitMethodTypography>
              ))}
            </S.InfoColumn>
          </S.StyledButtton>

          <S.StyledButtton
            onClick={() => {
              nextModal.setNextDialog(body.rightDialog);
              nextModal.setNextMethodBody(rightModal);
            }}
            variant="contained"
            style={
              isSmall
                ? { marginTop: theme.spacing(1), padding: "10px" }
                : { marginLeft: theme.spacing(1) }
            }
          >
            <S.InfoColumn>
              <S.HeadUnitMethodTypography variant="BodyTitle">
                {body.rightTitle}
              </S.HeadUnitMethodTypography>
              {body.rightSide.map((name) => (
                <S.HeadUnitMethodTypography variant="Body">
                  {name === "Only usable on iLevel < 18.11"
                    ? name +
                      " Current iLevel: (" +
                      carProps.i_level.split("-")[1] +
                      "." +
                      carProps.i_level.split("-")[2] +
                      ")"
                    : name}
                </S.HeadUnitMethodTypography>
              ))}
            </S.InfoColumn>
          </S.StyledButtton>
        </S.InfoRow>
      </S.BodyWrapper>
    </S.StyledDialog>
  );
};
export default Equipment;
