import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as S from "./LoadingSearch.styled";

const PrecheckLoader = ({ currentVin, finished }) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 95
          ? 95
          : prevProgress +
            Math.random() * (Math.floor(5) - Math.ceil(1)) +
            Math.ceil(1)
      );
    }, Math.random() * (Math.floor(1500) - Math.ceil(1200)) + Math.ceil(1200));
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <S.Container>
      <S.StyledPaper>
        <S.InfoRows>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              size={100}
              variant="static"
              value={finished ? 100 : progress}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                style={{
                  fontSize: 24,
                  fontWeight: 500,
                  color: theme.palette.primary.light,
                }}
              >
                {`${
                  finished
                    ? 100
                    : Math.round(progress) <= 95
                    ? Math.round(progress)
                    : 95
                }%`}
              </Typography>
            </Box>
          </Box>
        </S.InfoRows>
        <S.InfoRows>
          <S.LoadingSearchTypography gutterBottom variant="vin">
            ({currentVin})
          </S.LoadingSearchTypography>
        </S.InfoRows>
        <S.InfoRows>
          <S.LoadingSearchTypography variant="waiting">
            Please wait
          </S.LoadingSearchTypography>
        </S.InfoRows>
        <S.InfoRows>
          <S.LoadingSearchTypography variant="collect">
            Collecting available data...
          </S.LoadingSearchTypography>
        </S.InfoRows>
      </S.StyledPaper>
    </S.Container>
  );
};

export default PrecheckLoader;
