import { React, useState, useContext, useEffect } from "react";
import { StyledButton, StyledTypography } from "../../GlobalComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import { useTheme, useMediaQuery } from "@material-ui/core";
import bmwCarImage from "../../images/bmwCarImageSmall1.png";
import bmwCarImage2 from "../../images/bmwCarImageSmall2.png";
import bmwCarImage3 from "../../images/bmwCarImageSmall3.png";
import axios from "axios";
import ContextApi from "../../ContextApi";
import Loading from "../GlobalComponents/Loading";
import * as S from "./Subscriptions.styled";
import useStore from "../../utils/useStore";
import { observer } from "mobx-react";

const Subscriptions = () => {
  const PropsContext = useContext(ContextApi);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [creditCount, setCreditCount] = useState(20);
  const [subscriptions, setSubscriptions] = useState();
  const [credits, setCredits] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { store } = useStore();
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCreditCount(value);
  };

  useEffect(() => {
    if (!PropsContext.isLoading) {
      setSubscriptions(PropsContext.productProps.subscription);
      setCredits(PropsContext.productProps.credits);
      setLoading(false);
    }
  }, [PropsContext.isLoading]);

  const handleBuy = (product_id, quantity, type) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/paypalIntent",
        {
          product_id: product_id,
          quantity: quantity,
          type: type,
          callback_url: window.location.href,
        },

        { withCredentials: true }
      )
      .then((res) => {
        window.open(res.data.redirectUrl);
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <S.Container>
      <S.ContainerColumn>
        {localStorage.getItem("subscritpionId") !== "null" && (
          <S.CurrentSubscriptionContainer
            style={{
              width: "100%",
              marginBottom: "20px",
              backgroundColor: "#eef0f3",
            }}
          >
            {localStorage.getItem("subscritpionId") === "4" ? (
              <S.SubscriptionImage src={bmwCarImage} alt={"car"} />
            ) : localStorage.getItem("subscritpionId") === "5" ? (
              <S.SubscriptionImage src={bmwCarImage3} alt={"car"} />
            ) : (
              localStorage.getItem("subscritpionId") === "6" && (
                <S.SubscriptionImage src={bmwCarImage2} alt={"car"} />
              )
            )}
            <S.SubscriptionsTypography variant="subscriptionBody">
              You have active subscription{" "}
              {localStorage.getItem("subscritpionId") === "4" ? (
                <S.SubscriptionsTypography variant="subscriptionBody">
                  M3
                </S.SubscriptionsTypography>
              ) : localStorage.getItem("subscritpionId") === "5" ? (
                <S.SubscriptionsTypography variant="subscriptionBody">
                  M4
                </S.SubscriptionsTypography>
              ) : (
                localStorage.getItem("subscritpionId") === "6" && (
                  <S.SubscriptionsTypography variant="subscriptionBody">
                    M5
                  </S.SubscriptionsTypography>
                )
              )}{" "}
              Pack which is valid until{" "}
              {new Date(
                localStorage.getItem("subscritpionValidTill")
              ).toLocaleString()}
              .
            </S.SubscriptionsTypography>
          </S.CurrentSubscriptionContainer>
        )}

        <S.Wrapper style={isExtraSmall ? { flexDirection: "column" } : {}}>
          <S.PackageWrapper style={isExtraSmall ? { maxWidth: "none" } : {}}>
            <S.CustomPackageContainer
              style={isSmall || isExtraSmall ? { padding: "10px" } : {}}
            >
              <S.CreditColumns style={{ marginBottom: theme.spacing(2) }}>
                <StyledTypography
                  style={{
                    height: "50%",
                    width: "50%",
                  }}
                  variant="h3"
                >
                  Enter Amount
                </StyledTypography>
                <StyledTypography
                  style={{
                    height: "50%",
                    width: "50%",
                  }}
                  variant="subtitle1"
                >
                  1 Credit = €1
                </StyledTypography>
                <S.Input
                  type="number"
                  style={{
                    height: "100%",
                    width: "50%",
                  }}
                  value={creditCount}
                  label="Invite Code"
                  variant="filled"
                  onChange={handleChange}
                />
              </S.CreditColumns>
              <S.CreditColumns
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <S.StyledButtton
                  onClick={() =>
                    handleBuy(credits[0].id, creditCount, "credits")
                  }
                >
                  <Typography
                    variant="h5"
                    style={{
                      padding: "5px",
                    }}
                  >
                    <FontAwesomeIcon icon="shopping-cart" /> Secure pay with
                    PayPal
                  </Typography>
                </S.StyledButtton>

                <S.SubscriptionsTypography
                  variant="subscriptionBody"
                  style={{ marginTop: theme.spacing(4) }}
                >
                  Your Payment Is 100% Secure And Verified
                </S.SubscriptionsTypography>
              </S.CreditColumns>
            </S.CustomPackageContainer>
            <S.ExactPackageContainer>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="carTitle">
                  50 Credits
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="h6">
                  <FontAwesomeIcon icon="gift" style={{ color: "#1dbf59" }} />{" "}
                  Get 1.25 bonus
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.StyledExactButtton
                  fullWidth
                  onClick={() => handleBuy(credits[0].id, 50, "credits")}
                >
                  <Typography variant="h4">Buy now</Typography>
                </S.StyledExactButtton>
              </S.ExactCreditColumns>
            </S.ExactPackageContainer>
            <S.ExactPackageContainer>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="priceTitle">
                  100 Credits
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="h6">
                  <FontAwesomeIcon icon="gift" style={{ color: "#1dbf59" }} />{" "}
                  Get 5 bonus
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.StyledExactButtton
                  fullWidth
                  onClick={() => handleBuy(credits[0].id, 100, "credits")}
                >
                  <Typography variant="h4">Buy now</Typography>
                </S.StyledExactButtton>
              </S.ExactCreditColumns>
            </S.ExactPackageContainer>
            <S.ExactPackageContainer>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="priceTitle">
                  200 Credits
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="h6">
                  <FontAwesomeIcon icon="gift" style={{ color: "#1dbf59" }} />{" "}
                  Get 20 bonus
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.StyledExactButtton
                  fullWidth
                  onClick={() => handleBuy(credits[0].id, 200, "credits")}
                >
                  <Typography variant="h4">Buy now</Typography>
                </S.StyledExactButtton>
              </S.ExactCreditColumns>
            </S.ExactPackageContainer>
            <S.ExactPackageContainer>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="priceTitle">
                  250 Credits
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.SubscriptionsTypography variant="h6">
                  <FontAwesomeIcon icon="gift" style={{ color: "#1dbf59" }} />{" "}
                  Get 31.25 bonus
                </S.SubscriptionsTypography>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.StyledExactButtton
                  fullWidth
                  onClick={() => handleBuy(credits[0].id, 250, "credits")}
                >
                  <Typography variant="h4">Buy now</Typography>
                </S.StyledExactButtton>
              </S.ExactCreditColumns>
            </S.ExactPackageContainer>
          </S.PackageWrapper>

          <S.SubscriptionContainer
            style={
              isSmall
                ? { marginLeft: "10px", padding: "10px" }
                : isExtraSmall
                ? { marginLeft: "0px", padding: "10px" }
                : { marginLeft: "10px" }
            }
          >
            <S.SubscriptionsTypography variant="h3">
              Order Subscription and Save
            </S.SubscriptionsTypography>
            <S.CreditRows style={isSmall ? { flexDirection: "column" } : {}}>
              <S.ExactCreditColumns>
                <S.CreditRows>
                  <S.Ellipse
                    style={{
                      backgroundColor: "rgba(2, 123, 254, 0.2)",
                    }}
                  >
                    <S.Ellipse2
                      style={{
                        backgroundColor: "rgba(2, 123, 254, 0)",
                        border: "solid 2px rgba(2, 123, 254, 0.4)",
                      }}
                    >
                      <S.ContainerWrapper src={bmwCarImage} alt={"car"} />
                    </S.Ellipse2>
                  </S.Ellipse>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography variant="carTitle">
                    M3
                  </S.SubscriptionsTypography>
                </S.CreditRows>

                <S.CreditRows>
                  <S.SubscriptionsTypography variant="subscriptionBody">
                    <S.CheckIcon icon="check-circle" />
                    Unlimited repair packs per month
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography
                    variant={subscriptions[0].promo_fiat ? "oldPrice" : "price"}
                  >
                    € {subscriptions[0].price_fiat} /month
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                {subscriptions[0].promo_fiat && (
                  <S.CreditRows>
                    <S.SubscriptionsTypography variant="discountedPrice">
                      € {subscriptions[0].promo_fiat} /month
                    </S.SubscriptionsTypography>
                  </S.CreditRows>
                )}
                <S.CreditRows
                  style={isSmall ? { marginBottom: theme.spacing(2) } : {}}
                >
                  <StyledButton
                    onClick={() =>
                      handleBuy(subscriptions[0].id, 1, "subscription")
                    }
                  >
                    <StyledTypography variant="button">
                      <FontAwesomeIcon icon="shopping-cart" /> Order now
                    </StyledTypography>
                  </StyledButton>
                </S.CreditRows>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.CreditRows>
                  <S.Ellipse
                    style={{
                      backgroundColor: "rgba(36, 156, 52, 0.2)",
                    }}
                  >
                    <S.Ellipse2
                      style={{
                        backgroundColor: "rgba(36, 156, 52, 0)",
                        border: "solid 2px rgba(36, 156, 52, 0.4)",
                      }}
                    >
                      <S.ContainerWrapper src={bmwCarImage2} alt={"car"} />
                    </S.Ellipse2>
                  </S.Ellipse>
                </S.CreditRows>

                <S.CreditRows>
                  <S.SubscriptionsTypography variant="carTitle">
                    M5
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                <S.CreditRows style={{ marginBottom: theme.spacing(1) }}>
                  <S.EllipsePopular>
                    <S.SubscriptionsTypography variant="popular">
                      POPULAR
                    </S.SubscriptionsTypography>
                  </S.EllipsePopular>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography variant="subscriptionBody">
                    <S.CheckIcon icon="check-circle" />
                    Unlimited map codes per month <br />
                    (including NBT EVO ID4)
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography variant="subscriptionBody">
                    <S.CheckIcon icon="check-circle" />
                    Unlimited repair packs per month
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography
                    variant={subscriptions[2].promo_fiat ? "oldPrice" : "price"}
                  >
                    € {subscriptions[2].price_fiat} /month
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                {subscriptions[2].promo_fiat && (
                  <S.CreditRows>
                    <S.SubscriptionsTypography variant="discountedPrice">
                      € {subscriptions[2].promo_fiat} /month
                    </S.SubscriptionsTypography>
                  </S.CreditRows>
                )}
                <S.CreditRows
                  style={isSmall ? { marginBottom: theme.spacing(2) } : {}}
                >
                  <StyledButton
                    onClick={() =>
                      handleBuy(subscriptions[2].id, 1, "subscription")
                    }
                  >
                    <StyledTypography variant="button">
                      <FontAwesomeIcon icon="shopping-cart" /> Order now
                    </StyledTypography>
                  </StyledButton>
                </S.CreditRows>
              </S.ExactCreditColumns>
              <S.ExactCreditColumns>
                <S.CreditRows>
                  <S.Ellipse
                    style={{
                      backgroundColor: "rgba(212, 82, 82, 0.2)",
                    }}
                  >
                    <S.Ellipse2
                      style={{
                        backgroundColor: "rgba(212, 82, 82, 0)",
                        border: "solid 2px rgba(212, 82, 82, 0.4)",
                      }}
                    >
                      <S.ContainerWrapper src={bmwCarImage3} alt={"car"} />
                    </S.Ellipse2>
                  </S.Ellipse>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography variant="carTitle">
                    M4
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography variant="subscriptionBody">
                    <S.CheckIcon icon="check-circle" />
                    Unlimited map codes per month <br />
                    (including NBT EVO ID4)
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                <S.CreditRows>
                  <S.SubscriptionsTypography
                    variant={subscriptions[1].promo_fiat ? "oldPrice" : "price"}
                  >
                    € {subscriptions[1].price_fiat} /month
                  </S.SubscriptionsTypography>
                </S.CreditRows>
                {subscriptions[1].promo_fiat && (
                  <S.CreditRows>
                    <S.SubscriptionsTypography variant="discountedPrice">
                      € {subscriptions[1].promo_fiat} /month
                    </S.SubscriptionsTypography>
                  </S.CreditRows>
                )}
                <S.CreditRows
                  style={isSmall ? { marginBottom: theme.spacing(2) } : {}}
                >
                  <StyledButton
                    onClick={() =>
                      handleBuy(subscriptions[1].id, 1, "subscription")
                    }
                  >
                    <StyledTypography variant="button">
                      <FontAwesomeIcon icon="shopping-cart" /> Order now
                    </StyledTypography>
                  </StyledButton>
                </S.CreditRows>
              </S.ExactCreditColumns>
            </S.CreditRows>
          </S.SubscriptionContainer>
        </S.Wrapper>
      </S.ContainerColumn>
    </S.Container>
  );
};
export default observer(Subscriptions);
