import React from "react";
import Index from "../../components/Search";

const Search = () => {
  return (
    <>
      <Index></Index>
    </>
  );
};

export default Search;
