import React from "react";
import { StyledTypography } from "../../GlobalComponents";
import bmwLogo from "../../images/bmwLogoWhite.png";
import { useTheme } from "@material-ui/core/styles";

import * as S from "./Footer.styled";
const Footer = () => {
  const theme = useTheme();

  return (
    <S.FooterContainer>
      <S.FooterInformationWrapper>
        <StyledTypography
          variant="h2"
          style={{ color: theme.palette.primary.light }}
        >
          <img src={bmwLogo} alt="Logo" height={15} width={15} /> BavarianVIN
        </StyledTypography>
        <StyledTypography variant="subtitle1" style={{ fontSize: "12px" }}>
          World’s First Blockchain-based Platform to Check BMW vehicle History
          <br />
          Welcome to BavarianVIN. Here you can perform a VIN check to get a full
          vehicle history report. Long gone are the days when it was nearly
          impossible to find a complete history of a vehicle.
        </StyledTypography>
      </S.FooterInformationWrapper>
      <hr />
      <S.FooterInformationWrapper>
        <StyledTypography variant="subtitle1" style={{ fontSize: "10px" }}>
          BavarianVIN.com © All rights reserved {new Date().getFullYear()}{" "}
          <br /> If you need any help or if you have questions about API, please
          contact us: info@bavarianvin.com
        </StyledTypography>
      </S.FooterInformationWrapper>
    </S.FooterContainer>
  );
};

export default Footer;
