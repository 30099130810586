import React from "react";
import Index from "../../components/Purchases";

const Purchases = () => {
  return (
    <>
      <Index></Index>
    </>
  );
};

export default Purchases;
