import React from "react";
import { StyledTypography } from "../../GlobalComponents";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import * as S from "./FAQ.styled";

const FAQ = () => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.HeaderTitle variant="h5">Frequently Asked Questions</S.HeaderTitle>
        <S.Title>
          <StyledTypography variant="h3">GENERAL</StyledTypography>
        </S.Title>
        <S.StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "black" }} />}
          >
            <StyledTypography variant="h4">
              <S.StyledHelpOutlineIcon />
              Is every feature on the site is fully automatic or there is a
              waiting time?
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography variant="subtitle2">
              Everything, starting from the payment to service buying is fully
              INSTANT, there is no need to wait for someone to respond and send
              the map code, the system generates the code by itself.
            </StyledTypography>
          </AccordionDetails>
        </S.StyledAccordion>
        <S.StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "black" }} />}
          >
            <StyledTypography variant="h4">
              <S.StyledHelpOutlineIcon />
              What are the benefits of a monthly subscription?
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography variant="subtitle2">
              If you are doing constantly business such as replacing Headunits
              or selling map codes in huge quantities, we are able to provide
              you a wholesale price for unlimited services (repair packs or map
              codes) for 1 month, which may be more worth then buying separate
              services each time.
            </StyledTypography>
          </AccordionDetails>
        </S.StyledAccordion>
        <S.Title>
          <StyledTypography variant="h3">
            HEADUNIT ACTIVATION/FEATURE INSTALLER
          </StyledTypography>
        </S.Title>
        <S.StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "black" }} />}
          >
            <StyledTypography variant="h4">
              <S.StyledHelpOutlineIcon />
              What is needed to use feature installer?
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography variant="subtitle2">
              Laptop with working internet and car connected via ENET cable.
            </StyledTypography>
          </AccordionDetails>
        </S.StyledAccordion>
        <S.StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "black" }} />}
          >
            <StyledTypography variant="h4">
              <S.StyledHelpOutlineIcon />
              What is needed to use feature installer?
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography variant="subtitle2">
              The code is issued once you buy Headunit activation service, it
              always consists of 10 random characters.
            </StyledTypography>
          </AccordionDetails>
        </S.StyledAccordion>
        <S.StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fill: "black" }} />}
          >
            <StyledTypography variant="h4">
              <S.StyledHelpOutlineIcon />
              Where can I get the FeatureInstaller?
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails>
            <StyledTypography variant="subtitle2">
              <a
                href="https://mega.nz/#F!PQ8ihaSK!mifSYy5C2c3NdTb4D7tfiQ"
                rel="noreferrer"
              >
                FeatureInstaller
              </a>
            </StyledTypography>
          </AccordionDetails>
        </S.StyledAccordion>
      </S.Wrapper>
    </S.Container>
  );
};

export default FAQ;
