import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";

export const ServiceButton = withStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "left",
    display: "inline",
  },
}))(Button);

export const styledPaper = withStyles((theme) => ({
  root: {
    marginBottom: "10px",
  },
}))(Paper);

export const RefreshButton = withStyles((theme) => ({
  root: {
    borderRadius: "16px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    border: "solid 0.5px #b4d4ff",
    backgroundColor: "#dfeeff",
    width: "100%",
    zIndex: 600,
    "&:hover": {
      backgroundColor: `${darken("#dfeeff", 0.2)}`,
    },
  },
}))(Button);

export const StyledWrapper = styled.div`
  position: relative;

  display: flex;
  flex-flow: row;
  padding-top: 8px;
  justify-content: space-between;
`;

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    border: "1px solid #d5d5d5",
  },
}));

export const BodyColumns = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-flow: row;
`;

export const SearchRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 10px;
  width: 100%;
`;

export const SettingsColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: center;
  width: 200px;
`;

export const TitleColumn = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
`;

export const InfoColumn = styled.div`
  min-width: 190px;

  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: center;
`;

export const RefreshContainer = styled.div`
  border-radius: 16px;
  border: solid 0.5px #b4d4ff;
  background-color: #dfeeff;
  padding-right: 15px;
  padding-left: 5px;
`;

export const Img = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 150px;
  height: auto;
`;

export const ServiceImg = styled.img`
  width: 15px;
  height: 15px;
`;

export const RecentSearchTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  Service: {
    ...theme.typography.subtitle1,
    marginLeft: theme.spacing(1),
    fontWeight: 700,
    color: theme.palette.primary.light,
  },
  Settings: {
    ...theme.typography.subtitle1,
    marginLeft: theme.spacing(1),
    fontWeight: 700,
    textAlign: "left",
  },
  CarName: {
    ...theme.typography.h5,
    fontWeight: 700,
  },
  Refresh: {
    ...theme.typography.subtitle1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    color: "#01337e",
  },
  Title: {
    ...theme.typography.subtitle1,
    textAlign: "left",
  },
  Info: {
    ...theme.typography.h6,
    fontWeight: 700,
    textAlign: "left",
  },
  Subtitle: {
    ...theme.typography.h6,
    textAlign: "left",
    color: "#ababab",
  },
}))(Typography);

export const ButtonService = withStyles((theme) => ({
  root: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    maxHeight: "30px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 600,
    "&:hover": {
      backgroundColor: `${darken(theme.palette.primary.main, 0.2)}`,
    },
  },
}))(Button);

export const ButtonSettings = withStyles((theme) => ({
  root: {
    marginLeft: "-10px",

    marginTop: "auto",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    maxHeight: "30px",

    border: "solid 0.5px #e2e2e2",

    backgroundColor: theme.palette.primary.light,

    "&:hover": {
      backgroundColor: `${darken(theme.palette.primary.light, 0.2)}`,
    },
  },
}))(Button);
