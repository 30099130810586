import { React, useState, useContext } from "react";
import { Grid, Avatar, Typography } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import isEmail from "validator/es/lib/isEmail";
import isLength from "validator/es/lib/isLength";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import * as S from "../Modal.Styled";
import { StyledTypography } from "../../../GlobalComponents";
import ContextApi from "../../../ContextApi";
import useStore from "../../../utils/useStore";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const Login = ({ isOpen, setIsOpen, signUp, forgotPassword }) => {
  const theme = useTheme();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const { store } = useStore();
  const isEmailValid = email ? isEmail(email) : true;
  const isPasswordValid =
    password !== null ? isLength(password, { min: 7, max: 30 }) : true;
  const [open, setOpen] = useState(false);
  const PropsContext = useContext(ContextApi);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleLogin = (event) => {
    const res = axios
      .post(process.env.REACT_APP_API_URL + "/login", {
        email,
        password,
      })
      .then(async (res) => {
        Cookies.set("jwt", res.data.accessToken, {
          domain: ".bavarianvin.com",
          secure: false,
        });
        await store.refresh();

        Cookies.set("access_token", res.data.accessToken);
        PropsContext.setAuth(true);

        store.callToast("Welcome Back!", "success");
      })
      .catch((error) => {
        if (error.response.data === "Email not verified") {
          handleOpen();
        } else {
          store.callToast(error.response.data, "Error");
        }
      });
  };

  const resendEmail = (event) => {
    const res = axios
      .post(process.env.REACT_APP_API_URL + "/resend", {
        email,
      })
      .then(async (res) => {
        store.callToast(res.data, "success");
        handleClose();
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };
  return (
    <>
      <S.StyledDialog
        fullWidth
        maxWidth="xs"
        PaperProps={{
          style: {
            borderRadius: 16,
            background: "#1f2441",
            color: theme.palette.primary.light,
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <S.Container>
          <StyledTypography variant="h2">E-mail Verification</StyledTypography>
          <Typography
            variant="h6"
            style={{
              marginBottom: theme.spacing(2),
            }}
          >
            In order to activate your account, please verify your email.{" "}
          </Typography>
          <S.Button fullWidth onClick={() => resendEmail()}>
            Sign in
          </S.Button>
        </S.Container>
      </S.StyledDialog>
      <S.StyledDialog
        fullWidth
        maxWidth="xs"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: 16,
            background: "#1f2441",
            color: theme.palette.primary.light,
          },
        }}
      >
        <S.Container>
          <Grid>
            <Grid align="center">
              <Avatar style={{ backgroundColor: "#027bfe" }}>
                <LockOutlinedIcon />
              </Avatar>
              <StyledTypography
                variant="h2"
                style={{
                  marginBottom: theme.spacing(3),
                }}
              >
                Sign in
              </StyledTypography>
            </Grid>
            <S.TextField
              fullWidth
              value={email || ""}
              onChange={(e) => setEmail(e.target.value.trim())}
              label="Email address"
              variant="filled"
              error={!isEmailValid}
              helperText={isEmailValid ? null : "Enter valid email"}
            />
            <S.TextField
              fullWidth
              type="password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              variant="filled"
              error={!isPasswordValid}
              helperText={isPasswordValid ? null : "Too short"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  color="white"
                  labelStyle={{ color: theme.palette.primary.light }}
                  iconStyle={{ fill: theme.palette.primary.light }}
                  inputStyle={{ color: "black" }}
                  style={{ color: theme.palette.primary.light }}
                />
              }
              label="Remember me"
            />

            <S.Button
              fullWidth
              disabled={
                !isEmailValid || !isPasswordValid || !email || !password
              }
              onClick={() => handleLogin()}
            >
              Sign in
            </S.Button>

            <Typography>
              <Link
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                }}
                href="#"
                onClick={() => {
                  setIsOpen(false);
                  forgotPassword.setIsOpen(true);
                }}
              >
                Forgot password ?
              </Link>
            </Typography>
            <Typography>
              New user?
              <Link
                href="#"
                onClick={() => {
                  setIsOpen(false);
                  signUp.setIsOpen(true);
                }}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                }}
              >
                {" "}
                Sign Up
              </Link>
            </Typography>
          </Grid>
        </S.Container>
      </S.StyledDialog>
    </>
  );
};
export default observer(Login);
