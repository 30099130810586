import React, { useState, useContext } from "react";

import { useTheme, useMediaQuery } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledButton } from "../../GlobalComponents";

import bmwLogo from "../../images/bmwLogoWhite.png";
import * as S from "./Navbar.styled";
import Drawer from "./Drawer";
import BavarianVIN from "../../images/BavarianVIN.png";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@mui/material/Menu";
import ChangePassword from "../Login/ChangePassword";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import ContextApi from "../../ContextApi";

const Navbar = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const PropsContext = useContext(ContextApi);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openChangePassword, setOpenChangePassword] = useState(false);

  const handleChangePasswordOpen = () => {
    setAnchorEl(null);
    setOpenChangePassword(true);
  };

  const handleLogout = () => {
    PropsContext.setAuth(false);
    Cookies.remove("access_token");
    Cookies.remove("jwt");
    localStorage.removeItem("email");
    localStorage.removeItem("credits");

    handleClose();
  };
  return (
    <>
      <ChangePassword
        isOpen={openChangePassword}
        setIsOpen={setOpenChangePassword}
      />

      <S.NavContainer
        style={isExtraSmall ? { paddingTop: "0px", paddingBottom: "0px" } : {}}
      >
        {isExtraSmall ? (
          <S.NavItems>
            <Drawer />
            <S.NavLinks>
              <S.NavLink></S.NavLink>
            </S.NavLinks>
            <S.NavInfo>
              <S.NavLink to="/Subscriptions">
                <S.NavbarTypography variant="Credits">
                  {localStorage.getItem("credits")}
                </S.NavbarTypography>
                <S.NavbarTypography variant="Links">
                  <FontAwesomeIcon icon="plus-circle" />
                </S.NavbarTypography>
              </S.NavLink>
            </S.NavInfo>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle
                style={{
                  width: "40px",
                  height: "40px",
                  color: theme.palette.primary.light,
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: "right",
                vertical: 60, // TODO RENAME THIS CONSTANT
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ boxSizing: "border-box" }}
            >
              <S.NavLink to="/Subscriptions">
                <MenuItem onClick={handleClose}>
                  <S.NavbarTypography variant="Name">
                    {localStorage.getItem("email")}
                  </S.NavbarTypography>
                </MenuItem>
              </S.NavLink>
              <MenuItem onClick={handleChangePasswordOpen}>
                <S.NavbarTypography variant="Name">
                  Change Password
                </S.NavbarTypography>
              </MenuItem>
              <S.MenuLink to="/Invite">
                <MenuItem onClick={handleClose}>
                  <S.NavbarTypography variant="Name">
                    Invite and Earn
                  </S.NavbarTypography>
                </MenuItem>
              </S.MenuLink>

              <MenuItem onClick={handleLogout}>
                <S.NavbarTypography variant="Name">Logout</S.NavbarTypography>
              </MenuItem>
            </Menu>
          </S.NavItems>
        ) : isSmall ? (
          <S.NavItems>
            <Drawer />
            <S.NavLinks>
              <S.NavLink to="/Home">
                <img src={BavarianVIN} alt="Logo" height={40} width={200} />
              </S.NavLink>
            </S.NavLinks>
            <S.NavInfo>
              <S.NavLink to="/Subscriptions">
                <S.NavbarTypography variant="Credits">
                  Credits {localStorage.getItem("credits")}
                </S.NavbarTypography>
                <S.NavbarTypography variant="Links">
                  <FontAwesomeIcon icon="plus-circle" />
                </S.NavbarTypography>
              </S.NavLink>
            </S.NavInfo>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle
                style={{
                  width: "40px",
                  height: "40px",
                  color: theme.palette.primary.light,
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: "right",
                vertical: 60, // TODO RENAME THIS CONSTANT
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ boxSizing: "border-box" }}
            >
              <S.NavLink to="/Subscriptions">
                <MenuItem onClick={handleClose}>
                  <S.NavbarTypography variant="Name">
                    {localStorage.getItem("email")}
                  </S.NavbarTypography>
                </MenuItem>
              </S.NavLink>
              <MenuItem onClick={handleChangePasswordOpen}>
                <S.NavbarTypography variant="Name">
                  Change Password
                </S.NavbarTypography>
              </MenuItem>
              <S.MenuLink to="/Invite">
                <MenuItem onClick={handleClose}>
                  <S.NavbarTypography variant="Name">
                    Invite and Earn
                  </S.NavbarTypography>
                </MenuItem>
              </S.MenuLink>
              <MenuItem onClick={handleLogout}>
                <S.NavbarTypography variant="Name">Logout</S.NavbarTypography>
              </MenuItem>
            </Menu>
          </S.NavItems>
        ) : (
          <S.NavItems>
            <S.NavLinks>
              <S.NavLink to="/Home">
                <img src={bmwLogo} alt="Logo" height={20} width={20} />
                <img src={BavarianVIN} alt="Logo" height={40} width={200} />
              </S.NavLink>

              <S.NavLink to="/Home">
                <S.NavItem>
                  <S.NavbarTypography variant="Links">
                    <FontAwesomeIcon icon="home" />
                  </S.NavbarTypography>
                  <S.NavbarTypography variant="Links"> Home</S.NavbarTypography>
                </S.NavItem>
              </S.NavLink>

              <S.NavLink to="/Purchases">
                <S.NavItem>
                  <S.NavbarTypography variant="Links">
                    <FontAwesomeIcon icon="money-check" />
                  </S.NavbarTypography>
                  <S.NavbarTypography variant="Links">
                    Purchases
                  </S.NavbarTypography>
                </S.NavItem>
              </S.NavLink>

              <S.NavLink to="/Pricing">
                <S.NavItem>
                  <S.NavbarTypography variant="Links">
                    <FontAwesomeIcon icon="shopping-cart" />
                  </S.NavbarTypography>
                  <S.NavbarTypography variant="Links">
                    Pricing
                  </S.NavbarTypography>
                </S.NavItem>
              </S.NavLink>

              <S.NavLink to="/FAQ">
                <S.NavItem>
                  <S.NavbarTypography variant="Links">
                    <FontAwesomeIcon icon="question" />
                  </S.NavbarTypography>
                  <S.NavbarTypography variant="Links">FAQ</S.NavbarTypography>
                </S.NavItem>
              </S.NavLink>
            </S.NavLinks>
            <S.NavInfo>
              <S.NavLink to="/Subscriptions">
                <S.NavbarTypography variant="Credits">
                  Credits {localStorage.getItem("credits")}
                </S.NavbarTypography>
                <S.NavbarTypography variant="Links">
                  <FontAwesomeIcon icon="plus-circle" />
                </S.NavbarTypography>
              </S.NavLink>
              <S.NavLink to="/Invite">
                <StyledButton style={{ paddingRight: 0, paddingLeft: 5 }}>
                  <S.NavbarTypography variant="Links">
                    <FontAwesomeIcon icon="gift" />
                  </S.NavbarTypography>
                  <S.NavbarTypography variant="Links">
                    Invite and Earn
                  </S.NavbarTypography>
                </StyledButton>
              </S.NavLink>
            </S.NavInfo>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              style={{ paddingLeft: "10px", paddingRight: "0px" }}
            >
              <AccountCircle
                style={{
                  width: "40px",
                  height: "40px",
                  color: theme.palette.primary.light,
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: "right",
                vertical: 60, // TODO RENAME THIS CONSTANT
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ boxSizing: "border-box" }}
            >
              <S.NavLink to="/Subscriptions">
                <MenuItem onClick={handleClose}>
                  <S.NavbarTypography variant="Name">
                    {localStorage.getItem("email")}
                  </S.NavbarTypography>
                </MenuItem>
              </S.NavLink>
              <MenuItem onClick={handleChangePasswordOpen}>
                <S.NavbarTypography variant="Name">
                  Change Password
                </S.NavbarTypography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <S.NavbarTypography variant="Name">Logout</S.NavbarTypography>
              </MenuItem>
            </Menu>
          </S.NavItems>
        )}
      </S.NavContainer>
    </>
  );
};
export default Navbar;
