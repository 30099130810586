import { React, useState, useContext } from "react";
import { Grid, Avatar, Typography, Link } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useTheme } from "@material-ui/core/styles";
import * as S from "../Modal.Styled";
import { StyledTypography } from "../../../GlobalComponents";
import isEmail from "validator/es/lib/isEmail";
import axios from "axios";
import ContextApi from "../../../ContextApi";
import useStore from "../../../utils/useStore";
import { observer } from "mobx-react";

const ForgotPassword = ({ isOpen, setIsOpen, login }) => {
  const theme = useTheme();
  const [email, setEmail] = useState(null);
  const isEmailValid = email ? isEmail(email) : true;
  const PropsContext = useContext(ContextApi);
  const { store } = useStore();

  const handleChangePassword = async (event) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/forgot", {
        email,
      })
      .then(() => {
        store.callToast(
          "We've emailed you instructions to reset your password.",
          "success"
        );
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  return (
    <S.StyledDialog
      fullWidth
      maxWidth="xs"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      PaperProps={{
        style: {
          borderRadius: 16,
          background: "#1f2441",
          color: theme.palette.primary.light,
        },
      }}
    >
      <Grid>
        <S.Container>
          <Grid align="center">
            <Avatar style={{ backgroundColor: "#027bfe" }}>
              <LockOutlinedIcon />
            </Avatar>
            <StyledTypography
              variant="h2"
              style={{
                marginBottom: theme.spacing(3),
              }}
            >
              Forgot password
            </StyledTypography>
          </Grid>
          <S.TextField
            fullWidth
            value={email || ""}
            onChange={(e) => setEmail(e.target.value.trim())}
            label="Email address"
            variant="filled"
            error={!isEmailValid}
            helperText={isEmailValid ? null : "Enter valid email"}
          />
          <S.Button
            onClick={handleChangePassword}
            disabled={!isEmailValid || !email}
            fullWidth
          >
            Reset Password
          </S.Button>
          <Typography>
            Back to
            <Link
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
              href="#"
              onClick={() => {
                setIsOpen(false);
                login.setIsOpen(true);
              }}
            >
              {" "}
              Login
            </Link>
          </Typography>
        </S.Container>
      </Grid>
    </S.StyledDialog>
  );
};
export default observer(ForgotPassword);
