import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { StyledButton, InputTextField } from "../../GlobalComponents";

export const Container = styled.div`
  background: #1f2441;
  padding: 30px;
  border-radius: 8px;
`;

export const StyledDialog = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(Dialog);

export const Button = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))(StyledButton);

export const TextField = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))(InputTextField);
