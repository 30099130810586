import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@material-ui/core/TextField";

export const StyledDialog = withStyles((theme) => ({
  root: {},
}))(Dialog);

export const StyledDialogTitle = withStyles((theme) => ({
  root: {
    backgroundimage: "BLACK",
  },
}))(DialogTitle);

export const Img = styled.img`
width:100%; height 100%;
background-color:'white';

`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 30px 20px 30px 20px;
  justify-content: center;

  background-image: linear-gradient(to right, #1f2441 0%, #027bfe 100%);
`;

export const InfoColumn = styled.div`
  width: 50%;
  padding: 15px;
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: center;
`;

export const InfoRow = styled.div`
  padding-bottom: 10px;
  justify-content: center;
  white-space: pre-line;
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
`;
export const BodyWrapper = styled.div`
  background-color: white;
`;
export const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d2d2d2",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputBase-root": {
      color: "black",
    },
    "& .MuiFilledInput ": {
      color: "gray",
    },

    "& fieldset": {
      borderRadius: 16,
    },
    minWidth: "200px",
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& > fieldset": {
        backgroundColor: "#DCDCDC",
      },
    },
  },
}))(TextField);

export const HeadUnitActivationTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    textAlign: "Left",
  },
  Title: {
    ...theme.typography.subtitle1,
  },
  Subtitle: {
    ...theme.typography.h5,
    fontWeight: 700,
  },
  Body: {
    ...theme.typography.h6,
    fontWeight: 700,

    color: theme.palette.primary.darkBlue,
  },
  BodyPriceNew: {
    ...theme.typography.h6,
    fontWeight: 700,

    color: theme.palette.primary.main,
  },
  BodyPriceOld: {
    ...theme.typography.h6,
    color: theme.palette.primary.darkBlue,
  },
  BodyInfo: {
    ...theme.typography.h6,

    color: theme.palette.primary.darkBlue,
  },
  BodyInfotext: {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.darkBlue,
  },
  disclaimer: {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
}))(Typography);

export const StyledButtton = withStyles((theme) => ({
  root: {
    height: "50px",
    backgroundColor: "#1dbf59",
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: `${darken("#1dbf59", 0.2)}`,
    },
  },
}))(Button);
