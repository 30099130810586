import { action, extendObservable } from "mobx";
import axios from "axios";

export class GlobalStore {
  constructor() {
    extendObservable(this, {
      Toast: { text: undefined, type: null },
      isToastOpen: false,
      callToast: action(function (text, type) {
        this.isToastOpen = true;

        this.Toast = {
          text,
          type,
        };
      }),
      refresh: action(function () {
        const res = axios
          .get(process.env.REACT_APP_API_URL + "/refresh", {
            withCredentials: true,
          })
          .then((res) => {
            localStorage.setItem("search_count", res.data.search_count);
            localStorage.setItem("email", res.data.email);
            localStorage.setItem("credits", res.data.credits);
            localStorage.setItem("userFromInvites", res.data.invited_amount);
            localStorage.setItem(
              "subscritpionValidTill",
              res.data.subscritpion_valid_till
            );
            localStorage.setItem("subscritpionId", res.data.subscritpion_id);

            localStorage.setItem(
              "creditsFromInvites",
              res.data.invite_bonus_credits
            );
            localStorage.setItem("inviteCode", res.data.invite_code);
          })
          .catch((error) => {
            console.log(error.response.data);
          });
      }),
    });
  }
}

export const store = new GlobalStore();
