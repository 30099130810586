import styled from "styled-components";
import Map from "../../images/Map.png";

import { StyledContainer } from "../../GlobalComponents";

export const Container = styled(StyledContainer)`
  padding: 150px 8px 0px 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  background-image: url(${Map});
  background-size: 60%;
  background-repeat: no-repeat;
  justify-content: space-between;
  max-width: 1200px;
  padding-right: 8px;
  padding-left: 8px;
`;

export const LeftContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  height: 50em;
  width: 40%;
`;

export const Ellipse = styled.div`
  width: 70%;
  padding-top: 5%;
  padding-left: 5%;
  height: 0;
  padding-bottom: 65%;
  border: solid 2px rgba(2, 123, 254, 0.2);
  border-radius: 50%;
`;

export const Ellipse2 = styled.div`
  position: relative;
  width: 95%;
  height: 0;
  padding-bottom: 95%;
  background-color: rgba(2, 123, 254, 0.2);
  border-radius: 50%;
`;

export const ContainerWrapper = styled.img`
  z-index: 1;
  position: absolute;
  width: 200%;
  left: -60%;
  top: -20%;
`;
