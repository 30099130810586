import React from "react";
import SearchBar from "../SearchBar";
import * as S from "./Search.styled";
const Search = ({
  isSearching,
  setIsSearching,
  currentVin,
  setCurrentVin,
  finished,
  setFinished,
}) => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.SearchRow>
          <S.SearchTypography variant="Title">
            Enter VIN and get a car report instantly
          </S.SearchTypography>
        </S.SearchRow>
        <SearchBar
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          currentVin={currentVin}
          setCurrentVin={setCurrentVin}
          finished={finished}
          setFinished={setFinished}
        />
      </S.Wrapper>
    </S.Container>
  );
};
export default Search;
