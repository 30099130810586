import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { React, useEffect, useState } from "react";
import isEmail from "validator/es/lib/isEmail";
import * as S from "./HeadUnitActivation.styled";

import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import { observer } from "mobx-react";
import useStore from "../../../../utils/useStore";

const Equipment = ({
  isOpen,
  setIsOpen,
  carProps,
  body,
  services,
  regions,
  regionVersions,
}) => {
  const theme = useTheme();
  const [email, setEmail] = useState(null);
  const [changeVin, setChangeVin] = useState(false);
  const [mapVersion, setMapVersion] = useState("");
  const [picklistregion, setPicklistRegion] = useState("");
  const [picklistRegionList, setPicklistRegionList] = useState([]);
  const [picklistRegionVersionList, setPicklistRegionVersionList] = useState(
    []
  );

  const [pickliststeeringWheel, setPicklistSteeringWheel] = useState("Left");
  const [checkedVIM, setCheckedVIM] = useState(false);
  const [checkedFSCP, setCheckedFSCP] = useState(false);
  const [activationBody, setActivationBody] = useState(null);
  const [picklistpackage, setPickListPackage] = useState();
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carVin, setCarVin] = useState(carProps.vin);
  const { store } = useStore();
  const isEmailValid = email ? isEmail(email) : true;
  const [params, setParams] = useState([]);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangeVin = (event) => {
    setChangeVin(!changeVin);
  };

  const handleChangingVin = (event) => {
    const {
      target: { value },
    } = event;
    setCarVin(value);
  };

  const handlepicklistesteeringWheelChange = (event) => {
    const {
      target: { value },
    } = event;
    setPicklistSteeringWheel(value);
  };

  const handlepicklisteregionChange = (event) => {
    const {
      target: { value },
    } = event;
    setPicklistRegion(value);
  };

  const handlepicklistemapVersionChange = (event) => {
    const {
      target: { value },
    } = event;
    setMapVersion(value);
  };

  const handlepicklistpackageChange = (event) => {
    const {
      target: { value },
    } = event;
    setPickListPackage(value);
    if (activationBody.bodyTitle === "Feature Code") {
      switch (value) {
        case activationBody.packagepicklistvalue[0]:
          return getBody("FeatureCode FullPack");
        case activationBody.packagepicklistvalue[1]:
          return getBody("FeatureCode VIM");
        case activationBody.packagepicklistvalue[2]:
          return getBody("FeatureCode FSCP");
        default:
          return null;
      }
    }
  };
  console.log(picklistpackage);
  const getBody = (bodyName) => {
    setActivationBody({
      ...activationBody,
      ...services.find((el) => {
        return el.title === bodyName;
      }),
    });
  };

  useEffect(() => {
    getRegions();
    setActivationBody(body);
    setState(!state);
  }, [isOpen]);
  const getRegions = () => {
    if (body.regionpicklistvalue != null) {
      let regionsArray = [];
      {
        regions.map((region) => {
          if (body.bodyId === "NBT NEXT Full FSC USB Pack") {
            region.type === "NBT" && regionsArray.push(region);
          } else if (body.bodyId === "NBT EVOID4 Full FSC USB Pack") {
            region.type === "NBT EVO ID4" && regionsArray.push(region);
          } else if (
            body.bodyId === "NBT EVOID5 6 Full FSC USB Pack" ||
            body.bodyId === "FeatureCode FullPack"
          ) {
            region.type === "NBT EVO ID5/6" && regionsArray.push(region);
          } else {
            region.type === carProps.headunit && regionsArray.push(region);
          }
        });
        setPicklistRegionList(body.regionpicklistvalue.concat(regionsArray));
      }
    }
  };

  useEffect(() => {
    if (body.picklisttype.includes("mapVersion") && regionVersions) {
      let regionsVersionArray = [];
      // eslint-disable-next-line no-lone-blocks
      {
        regionVersions.map((regionVersion) => {
          picklistregion &&
            regionVersion.code.includes(picklistregion.substring(2)) &&
            regionsVersionArray.push(regionVersion);
        });

        setPicklistRegionVersionList(
          body.mapVersionPicklistValue.concat(regionsVersionArray)
        );
      }
    }
  }, [picklistregion]);
  useEffect(() => {
    if (picklistRegionVersionList[0]) {
      setMapVersion(picklistRegionVersionList[0].code);
    }
  }, [picklistRegionVersionList]);
  useEffect(() => {
    if (state) {
      if (
        activationBody.picklisttype.includes("region") &&
        picklistRegionList.length > 0
      ) {
        setPicklistRegion(picklistRegionList[0].code);
      }

      if (
        activationBody.picklisttype.includes("package") &&
        activationBody.packagepicklistvalue > 0
      ) {
        setPickListPackage(activationBody.packagepicklistvalue[0]);
      }
    }
  }, [state]);

  useEffect(() => {
    if (activationBody && activationBody.picklisttype.includes("package")) {
      setPickListPackage(activationBody.packagepicklistvalue[0]);
    }
  }, [activationBody && activationBody.bodyId]);

  console.log(activationBody);
  useEffect(() => {
    if (state) {
      if (checkedVIM && checkedFSCP) {
        getBody(activationBody.bodyId + " + VIM + Carplay Fullscreen");
      } else if (checkedVIM) {
        getBody(activationBody.bodyId + " + VIM");
      } else if (checkedFSCP) {
        getBody(activationBody.bodyId + " + Carplay Fullscreen");
      } else {
        getBody(activationBody.bodyId);
      }
    }
  }, [checkedVIM, checkedFSCP]);

  const handleVIMChange = () => {
    setCheckedVIM(!checkedVIM);
  };

  const handleFSCPChange = () => {
    setCheckedFSCP(!checkedFSCP);
  };

  const handleBuy = () => {
    if (!loading) {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/purchases",
          {
            productName: body.bodyId,
            vin: carVin,
            vim: checkedVIM,
            fscp: checkedFSCP,
            packages: activationBody.title.includes("FeatureCode FullPack"),
            vin_change: changeVin,
            region: picklistregion,
            regionVersion: mapVersion,
            hand: pickliststeeringWheel,
            params: params,
          },
          { withCredentials: true },
          setLoading(true)
        )

        .then((res) => {
          setLoading(false);
          window.open(process.env.REACT_APP_URL + "/Purchases", "_self");
        })
        .catch((error) => {
          setLoading(false);

          store.callToast(error.response.data, "Error");
        });
    }
  };

  const handleCheckboxChoiceSimple = (title, value, fieldValue) => {
    if (params.some((item) => item["title"] === title) && fieldValue === null) {
      setParams(params.filter((item) => item["title"] !== title));
    } else if (fieldValue !== null) {
      setParams([
        ...params.filter((item) => item["title"] !== title),
        {
          ...params.find((item) => item.title === title),
          fieldValue: fieldValue,
        },
      ]);
    } else {
      setParams([
        ...params,
        { title: title, value: value, fieldValue: fieldValue },
      ]);
    }
  };

  const handleCheckboxChoiceAdvanced = (title, value, fieldValue) => {
    if (fieldValue !== null) {
      setParams([
        {
          ...params.find((item) => item.title === title),
          fieldValue: fieldValue,
        },
      ]);
    } else {
      setParams([{ title: title, value: value, fieldValue: fieldValue }]);
    }
  };

  return state ? (
    <S.StyledDialog
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      fullWidth
      maxWidth="xs"
      close={() => setIsOpen(false)}
      PaperProps={{
        style: { borderRadius: 16, background: "transparent" },
      }}
    >
      <S.TitleWrapper
        style={isSmall ? { flexFlow: "column", alignItems: "center" } : {}}
      >
        <S.HeadUnitActivationTypography variant="Subtitle">
          {activationBody.bodyTitle}
        </S.HeadUnitActivationTypography>
      </S.TitleWrapper>
      <S.BodyWrapper
        style={isSmall ? { padding: "20px" } : { padding: "30px" }}
      >
        <S.InfoRow>
          <S.HeadUnitActivationTypography variant="disclaimer">
            {activationBody.bodyId === "FeatureCode FullPack"
              ? activationBody.topDisclaimer
              : activationBody.topDisclaimer &&
                activationBody.topDisclaimer.includes("Only usable")
              ? activationBody.topDisclaimer +
                " (" +
                carProps.i_level.split("-")[1] +
                "." +
                carProps.i_level.split("-")[2] +
                ") " +
                activationBody.topDisclaimer2
              : activationBody.topDisclaimer}
          </S.HeadUnitActivationTypography>
        </S.InfoRow>
        <S.InfoRow>
          <FormControl fullWidth variant="outlined">
            <InputLabel>VIN</InputLabel>
            <OutlinedInput
              onChange={handleChangingVin}
              defaultValue={carVin}
              disabled={changeVin ? false : true}
              label="VIN"
            />
          </FormControl>
        </S.InfoRow>
        {activationBody.specificCheckboxList !== null &&
          activationBody.specificCheckboxList.includes("vinChange") && (
            <>
              <S.InfoRow style={{ padding: 0 }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleChangeVin} />}
                  label="Add VIN change (free)"
                />
              </S.InfoRow>
            </>
          )}
        {body.bodyId.includes(" CODING") &&
        !body.bodyId.includes(" ADVANCED CODING") ? (
          <>
            <S.InfoRow>
              <S.HeadUnitActivationTypography
                style={{
                  marginRight: theme.spacing(1),
                }}
                variant="BodyInfo"
              >
                Price for just 1:
              </S.HeadUnitActivationTypography>

              {services.find(
                (service) => service.title === body.bodyId + " 1 option"
              ) != null ? (
                <>
                  <S.HeadUnitActivationTypography
                    variant="BodyPriceOld"
                    style={{
                      textDecoration: "line-through",
                      paddingRight: 4,
                    }}
                  >
                    {parseFloat(
                      services.find(
                        (service) => service.title === body.bodyId + " 1 option"
                      ).price_credit
                    ).toFixed(2)}{" "}
                    credits
                  </S.HeadUnitActivationTypography>
                  <S.HeadUnitActivationTypography variant="BodyPriceNew">
                    {parseFloat(
                      services.find(
                        (service) => service.title === body.bodyId + " 1 option"
                      ).promo_credit
                    ).toFixed(2)}{" "}
                    credits
                  </S.HeadUnitActivationTypography>
                </>
              ) : (
                <S.HeadUnitActivationTypography variant="Body">
                  {parseFloat(
                    services.find(
                      (service) => service.title === body.bodyId + " 1 option"
                    ).price_credit
                  ).toFixed(2)}{" "}
                  credits
                </S.HeadUnitActivationTypography>
              )}
            </S.InfoRow>
            <S.InfoRow>
              <S.HeadUnitActivationTypography
                style={{
                  marginRight: theme.spacing(1),
                }}
                variant="BodyInfo"
              >
                Price for 1 to 3:
              </S.HeadUnitActivationTypography>

              {services.find(
                (service) => service.title === body.bodyId + " 3 options"
              ).promo_credit != null ? (
                <>
                  <S.HeadUnitActivationTypography
                    variant="BodyPriceOld"
                    style={{
                      textDecoration: "line-through",
                      paddingRight: 4,
                    }}
                  >
                    {parseFloat(
                      services.find(
                        (service) =>
                          service.title === body.bodyId + " 3 options"
                      ).price_credit
                    ).toFixed(2)}{" "}
                    credits
                  </S.HeadUnitActivationTypography>
                  <S.HeadUnitActivationTypography variant="BodyPriceNew">
                    {parseFloat(
                      services.find(
                        (service) =>
                          service.title === body.bodyId + " 3 options"
                      ).promo_credit
                    ).toFixed(2)}{" "}
                    credits
                  </S.HeadUnitActivationTypography>
                </>
              ) : (
                <S.HeadUnitActivationTypography variant="Body">
                  {parseFloat(
                    services.find(
                      (service) => service.title === body.bodyId + " 3 options"
                    ).price_credit
                  ).toFixed(2)}{" "}
                  credits
                </S.HeadUnitActivationTypography>
              )}
            </S.InfoRow>
            <S.InfoRow>
              <S.HeadUnitActivationTypography
                style={{
                  marginRight: theme.spacing(1),
                }}
                variant="BodyInfo"
              >
                Price for more than 3:
              </S.HeadUnitActivationTypography>

              {activationBody.promo_credit != null ? (
                <>
                  <S.HeadUnitActivationTypography
                    variant="BodyPriceOld"
                    style={{
                      textDecoration: "line-through",
                      paddingRight: 4,
                    }}
                  >
                    {parseFloat(activationBody.price_credit).toFixed(2)} credits
                  </S.HeadUnitActivationTypography>
                  <S.HeadUnitActivationTypography variant="BodyPriceNew">
                    {parseFloat(activationBody.promo_credit).toFixed(2)} credits
                  </S.HeadUnitActivationTypography>
                </>
              ) : (
                <S.HeadUnitActivationTypography variant="Body">
                  {parseFloat(activationBody.price_credit).toFixed(2)} credits
                </S.HeadUnitActivationTypography>
              )}
            </S.InfoRow>
          </>
        ) : (
          <S.InfoRow>
            <S.HeadUnitActivationTypography
              style={{
                marginRight: theme.spacing(1),
              }}
              variant="BodyInfo"
            >
              Price:
            </S.HeadUnitActivationTypography>

            {activationBody.promo_credit != null ? (
              <>
                <S.HeadUnitActivationTypography
                  variant="BodyPriceOld"
                  style={{
                    textDecoration: "line-through",
                    paddingRight: 4,
                  }}
                >
                  {parseFloat(activationBody.price_credit).toFixed(2)} credits
                </S.HeadUnitActivationTypography>
                <S.HeadUnitActivationTypography variant="BodyPriceNew">
                  {parseFloat(activationBody.promo_credit).toFixed(2)} credits
                </S.HeadUnitActivationTypography>
              </>
            ) : (
              <S.HeadUnitActivationTypography variant="Body">
                {parseFloat(activationBody.price_credit).toFixed(2)} credits
              </S.HeadUnitActivationTypography>
            )}
          </S.InfoRow>
        )}
        {activationBody.picklisttype.includes("package") && (
          <S.InfoRow>
            <FormControl fullWidth>
              <InputLabel>Package </InputLabel>
              <Select
                label="Select package"
                value={picklistpackage}
                onChange={handlepicklistpackageChange}
              >
                {activationBody.packagepicklistvalue.map((picklistpackage) => (
                  <MenuItem key={picklistpackage} value={picklistpackage}>
                    {picklistpackage}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.InfoRow>
        )}
        {activationBody.picklisttype.includes("region") &&
          ((activationBody.bodyTitle === "Feature Code" &&
            picklistpackage === activationBody.packagepicklistvalue[0]) ||
            activationBody.bodyTitle !== "Feature Code") && (
            <S.InfoRow>
              <FormControl fullWidth>
                <InputLabel>Select Region</InputLabel>
                <Select
                  label="Select Region"
                  value={picklistregion}
                  onChange={handlepicklisteregionChange}
                >
                  {picklistRegionList.map((picklistregion) => (
                    <MenuItem
                      key={picklistregion.code}
                      value={picklistregion.code}
                    >
                      {`[${picklistregion.code}] ${picklistregion.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </S.InfoRow>
          )}
        {activationBody.picklisttype.includes("mapVersion") && (
          <S.InfoRow>
            <FormControl fullWidth>
              <InputLabel>Select Map Version</InputLabel>
              <Select
                label="Select Map Version"
                value={mapVersion}
                onChange={handlepicklistemapVersionChange}
              >
                {picklistRegionVersionList.map((picklistregionVersion) => (
                  <MenuItem
                    key={picklistregionVersion.code}
                    value={picklistregionVersion.code}
                  >
                    {`[${picklistregionVersion.code}] ${picklistregionVersion.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.InfoRow>
        )}

        {activationBody.specificCheckboxList !== null &&
          activationBody.specificCheckboxList.includes("includeVim") &&
          ((activationBody.bodyId !== "NBT NEXT Full FSC USB Pack" &&
            activationBody.bodyId !== "FeatureCode FullPack") ||
            (activationBody.bodyId === "FeatureCode FullPack" &&
              picklistpackage === activationBody.packagepicklistvalue[0])) && (
            <>
              <S.InfoRow style={{ padding: 0 }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleVIMChange} />}
                  label="Include VIM (Video in Motion)"
                />
              </S.InfoRow>
            </>
          )}

        {activationBody.specificCheckboxList !== null &&
          activationBody.specificCheckboxList.includes("includeVim") &&
          (activationBody.bodyId === "NBT NEXT Full FSC USB Pack" ||
            (activationBody.bodyId === "FeatureCode FullPack" &&
              picklistpackage === activationBody.packagepicklistvalue[1])) && (
            <>
              <S.InfoRow style={{ padding: 0 }}>
                <FormControlLabel
                  control={<Checkbox defaultChecked disabled />}
                  label="Include VIM (Video in Motion)"
                />
              </S.InfoRow>
            </>
          )}

        {activationBody.specificCheckboxList !== null &&
          activationBody.specificCheckboxList.includes("includeFullScreen") &&
          (activationBody.bodyTitle !== "Feature Code" ||
            (activationBody.bodyTitle === "Feature Code" &&
              picklistpackage === body.packagepicklistvalue[0])) && (
            <>
              <S.InfoRow style={{ padding: 0 }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleFSCPChange} />}
                  label="Include Full Screen CarPlay"
                />
              </S.InfoRow>

              <S.InfoRow>
                <S.HeadUnitActivationTypography variant="BodyInfotext">
                  iLevel/iStep must be >= 18.03
                </S.HeadUnitActivationTypography>
                <S.HeadUnitActivationTypography variant="BodyInfotext">
                  {" "}
                  Current iLevel:{" "}
                  {carProps.i_level.split("-")[1] +
                    "." +
                    carProps.i_level.split("-")[2]}
                </S.HeadUnitActivationTypography>
              </S.InfoRow>
            </>
          )}
        {activationBody.bodyTitle === "Headunit Bin Packs" &&
          activationBody.checkboxList.length > 0 && (
            <S.InfoRow>
              <S.HeadUnitActivationTypography variant="BodyInfotext">
                Select Combinations:
              </S.HeadUnitActivationTypography>
            </S.InfoRow>
          )}
        {activationBody.textfieldList.length > 0 &&
          activationBody.textfieldList.map((list) => (
            <>
              {body.bodyId.includes("ADVANCED CODING") ? (
                <>
                  <S.InfoRow style={{ padding: 0 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={list.title}
                          checked={
                            params.length > 0 && params[0].title === list.title
                          }
                          onClick={(e) => {
                            handleCheckboxChoiceAdvanced(
                              list.title,
                              e.target.checked,
                              null
                            );
                          }}
                        />
                      }
                      label={list.title}
                    />
                  </S.InfoRow>
                  <S.InfoRow>
                    <S.HeadUnitActivationTypography variant="BodyInfotext">
                      {list.subtitle}
                    </S.HeadUnitActivationTypography>
                  </S.InfoRow>
                  <S.InfoRow>
                    <S.StyledTextField
                      fullWidth
                      // error={(e) => !isLength(e.target.value, { min: 1 })}
                      // helperText={"Must be 17 Digits"}
                      InputLabelProps={{
                        style: { color: "#6a6a6a" },
                      }}
                      variant="outlined"
                      disabled={
                        !params.some((item) => item["title"] === list.title)
                      }
                      onChange={(e) => {
                        handleCheckboxChoiceAdvanced(
                          list.title,
                          null,
                          e.target.value
                        );
                      }}
                    />
                  </S.InfoRow>
                </>
              ) : (
                <>
                  <S.InfoRow style={{ padding: 0 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={list.title}
                          onClick={(e) => {
                            handleCheckboxChoiceSimple(
                              list.title,
                              e.target.checked,
                              null
                            );
                          }}
                        />
                      }
                      label={list.title}
                    />
                  </S.InfoRow>
                  <S.InfoRow>
                    <S.HeadUnitActivationTypography variant="BodyInfotext">
                      {list.subtitle}
                    </S.HeadUnitActivationTypography>
                  </S.InfoRow>
                  <S.InfoRow>
                    <S.StyledTextField
                      fullWidth
                      InputLabelProps={{
                        style: { color: "#6a6a6a" },
                      }}
                      variant="outlined"
                      id={list.title}
                      disabled={
                        !params.some((item) => item["title"] === list.title)
                      }
                      onChange={(e) => {
                        handleCheckboxChoiceSimple(
                          list.title,
                          null,
                          e.target.value
                        );
                      }}
                    />
                  </S.InfoRow>
                </>
              )}
            </>
          ))}
        {activationBody.bodyTitle.length > 0 &&
          activationBody.checkboxList.map((list) => (
            <>
              <S.InfoRow style={{ padding: 0 }}>
                {body.bodyId.includes("ADVANCED CODING") ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={list.title}
                        checked={
                          params.length > 0 && params[0].title === list.title
                        }
                        onClick={(e) => {
                          handleCheckboxChoiceAdvanced(
                            list.title,
                            e.target.checked,
                            null
                          );
                        }}
                      />
                    }
                    label={list.title}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={list.title}
                        onClick={(e) => {
                          handleCheckboxChoiceSimple(
                            list.title,
                            e.target.checked,
                            null
                          );
                        }}
                      />
                    }
                    label={list.title}
                  />
                )}
              </S.InfoRow>

              <S.InfoRow>
                <S.HeadUnitActivationTypography variant="BodyInfotext">
                  {list.subtitle}
                </S.HeadUnitActivationTypography>
              </S.InfoRow>
            </>
          ))}
        {activationBody.picklisttype.includes("steeringWheel") &&
          ((activationBody.bodyTitle === "Feature Code" &&
            picklistpackage === activationBody.packagepicklistvalue[0]) ||
            picklistpackage === activationBody.packagepicklistvalue[2] ||
            activationBody.bodyTitle !== "Feature Code") && (
            <S.InfoRow>
              <FormControl fullWidth>
                <InputLabel>Steering Wheel Position </InputLabel>
                <Select
                  label="Select Steering Wheel Position"
                  value={pickliststeeringWheel}
                  onChange={handlepicklistesteeringWheelChange}
                >
                  {activationBody.steeringWheelpicklistvalue.map(
                    (pickliststeeringWheel) => (
                      <MenuItem
                        key={pickliststeeringWheel}
                        value={pickliststeeringWheel}
                      >
                        {pickliststeeringWheel}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </S.InfoRow>
          )}
        <S.InfoRow>
          <S.HeadUnitActivationTypography variant="BodyInfo">
            Send by email (optional)
          </S.HeadUnitActivationTypography>
        </S.InfoRow>
        <S.InfoRow>
          <S.StyledTextField
            InputLabelProps={{
              style: { color: "#6a6a6a" },
            }}
            style={{ marginBottom: theme.spacing(2) }}
            label="Enter e-mail"
            fullWidth
            value={email || ""}
            onChange={(e) => setEmail(e.target.value.trim())}
            variant="outlined"
            error={!isEmailValid}
            helperText={isEmailValid ? null : "Enter valid email"}
          />
        </S.InfoRow>
        <S.InfoRow>
          <S.StyledButtton
            disabled={
              (body.bodyId.includes(" CODING") && !params.length) ||
              (params.find(
                (item) =>
                  item.title ===
                  "I have full understanding how to use repair packs"
              ) &&
                params.find(
                  (item) =>
                    item.title ===
                    "I have full understanding how to use repair packs"
                ).value !== "undefined") !== true
            }
            onClick={handleBuy}
            fullWidth
          >
            {loading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <Typography variant="h4">Buy now</Typography>
            )}
          </S.StyledButtton>
        </S.InfoRow>
        <S.InfoRow>
          <S.HeadUnitActivationTypography variant="disclaimer">
            {activationBody.disclaimer}
          </S.HeadUnitActivationTypography>
        </S.InfoRow>
      </S.BodyWrapper>
    </S.StyledDialog>
  ) : null;
};
export default observer(Equipment);
