import { React, useState, useContext } from "react";
import { Grid, Avatar, Typography, Link } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import isEmail from "validator/es/lib/isEmail";
import { useTheme } from "@material-ui/core/styles";
import isLength from "validator/es/lib/isLength";
import Checkbox from "@material-ui/core/Checkbox";
import { StyledTypography } from "../../../GlobalComponents";
import * as S from "../Modal.Styled";
import axios from "axios";
import Cookies from "js-cookie";
import ContextApi from "../../../ContextApi";
import useStore from "../../../utils/useStore";
import { observer } from "mobx-react";

const Signup = ({ isOpen, setIsOpen, login }) => {
  const theme = useTheme();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [password, setPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const PropsContext = useContext(ContextApi);

  const isEmailValid = email ? isEmail(email) : true;
  const { store } = useStore();

  const isPasswordValid =
    password !== null ? isLength(password, { min: 7, max: 30 }) : true;
  const isRepeatPasswordValid =
    repeatPassword !== null
      ? isLength(repeatPassword, { min: 7, max: 30 }) &&
        isPasswordValid &&
        password === repeatPassword
      : true;

  const handleRegister = async (event) => {
    const res = await axios
      .post(process.env.REACT_APP_API_URL + "/signup", {
        invite_code: code,
        name,
        email,
        password,
      })
      .then(async (res) => {
        store.callToast(
          "Account Created Successfully. Check Your Email For Verification Code",
          "success"
        );
        window.open(process.env.REACT_APP_URL + "/Login", "_self");
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  return (
    <S.StyledDialog
      fullWidth
      maxWidth="xs"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      PaperProps={{
        style: {
          borderRadius: 16,
          background: "#1f2441",
          color: theme.palette.primary.light,
        },
      }}
    >
      <Grid>
        <S.Container>
          <Grid align="center">
            <Avatar style={{ backgroundColor: "#027bfe" }}>
              <AddCircleOutlineOutlinedIcon />
            </Avatar>
            <StyledTypography
              variant="h2"
              style={{
                marginBottom: theme.spacing(3),
              }}
            >
              Registration
            </StyledTypography>
          </Grid>
          <form>
            <S.TextField
              fullWidth
              value={code || ""}
              onChange={(e) => setCode(e.target.value.trim())}
              label="Invite Code"
              variant="filled"
            />
            <S.TextField
              fullWidth
              value={name || ""}
              onChange={(e) => setName(e.target.value.trim())}
              label="Name"
              variant="filled"
            />
            <S.TextField
              fullWidth
              value={email || ""}
              onChange={(e) => setEmail(e.target.value.trim())}
              label="Email address"
              variant="filled"
              error={!isEmailValid}
              helperText={isEmailValid ? null : "Enter valid email"}
            />
            <S.TextField
              fullWidth
              type="password"
              value={password || ""}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label="Password"
              variant="filled"
              error={!isPasswordValid}
              helperText={isPasswordValid ? null : "Too short"}
            />
            <S.TextField
              fullWidth
              type="password"
              value={repeatPassword || ""}
              onChange={(e) => setRepeatPassword(e.target.value)}
              label="Repeat password"
              variant="filled"
              error={!isRepeatPasswordValid}
              helperText={
                isRepeatPasswordValid
                  ? null
                  : "Too short or does not match password"
              }
            />
            <FormControlLabel
              style={{ marginBottom: theme.spacing(2) }}
              control={
                <Checkbox
                  name="checkedA"
                  labelStyle={{ color: theme.palette.primary.light }}
                  iconStyle={{ fill: theme.palette.primary.light }}
                  inputStyle={{ color: "black" }}
                  style={{ color: theme.palette.primary.light }}
                />
              }
              label="By ticking this box I agree that I will only use this tool for the vehicles, which are authorized by the owner to do so."
            />
            <S.Button
              onClick={handleRegister}
              disabled={
                !isEmailValid ||
                !isPasswordValid ||
                !isRepeatPasswordValid ||
                !email ||
                !code ||
                !password
              }
              fullWidth
            >
              Sign up
            </S.Button>
          </form>
          <Typography>
            Already have an account?
            <Link
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
              href="#"
              onClick={() => {
                setIsOpen(false);
                login.setIsOpen(true);
              }}
            >
              {" "}
              Login
            </Link>
          </Typography>
        </S.Container>
      </Grid>
    </S.StyledDialog>
  );
};
export default observer(Signup);
