import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import { StyledContainer } from "../../GlobalComponents";

export const Container = styled(StyledContainer)``;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;
export const HeaderTitle = styled.div`
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
`;

export const StyledTableHeaderCell = withStyles((theme) => ({
  root: {
    backgroundColor: "#eef0f3",
    color: theme.palette.primary.dark,
    borderBottom: "none",
  },
}))(TableCell);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    minWidth: 120,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
}))(TableRow);

export const StyledDiscoutBadge = withStyles((theme) => ({
  subtitle1: {
    padding: 2,
    fontSize: 10,
    textAlign: "center",
    textDecoration: "line-through",
    color: theme.palette.primary.light,
  },
}))(Typography);

export const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.error.main,
    borderRadius: 5,
    right: -20,
  },
}))(Badge);

export const PricingTypography = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  salePrice: {
    ...theme.typography.h6,
    color: "#0467FB",
    fontWeight: 700,
  },
  Price: {
    ...theme.typography.h6,
    fontWeight: 700,
  },
  OldPrice: {
    ...theme.typography.subtitle1,
    color: theme.palette.primary.light,
    textDecoration: "line-through",
  },
}))(Typography);
