import React, { useState } from "react";
import { StyledTypography } from "../../GlobalComponents";

import { useTheme, useMediaQuery } from "@material-ui/core";
import SearchBar from "../GlobalComponents/SearchBar";
import LoadingSearch from "../GlobalComponents/LoadingSearch";

import Link from "@material-ui/core/Link";
import bmwCarImage from "../../images/bmwCarImage.png";
import * as S from "./Home.styled";

const Home = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [isSearching, setIsSearching] = useState(false);
  const [currentVin, setCurrentVin] = useState(null);
  const [finished, setFinished] = useState(false);

  if (isSearching) {
    return <LoadingSearch currentVin={currentVin} finished={finished} />;
  }

  return (
    <S.Container>
      <S.Wrapper
        style={
          isSmall
            ? {
                justifyContent: "center",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }
            : {}
        }
      >
        <S.LeftContainer
          style={
            isExtraSmall
              ? {
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}
          }
        >
          <StyledTypography variant="h1">
            Providing solution for <br />
            BMW Workshops
          </StyledTypography>
          <StyledTypography
            variant="h4"
            style={{
              color: "#0467FB",
              fontWeight: 700,
              marginBottom: theme.spacing(5),
            }}
          >
            First two searches are free
          </StyledTypography>

          <SearchBar
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            currentVin={currentVin}
            setCurrentVin={setCurrentVin}
            finished={finished}
            setFinished={setFinished}
          />
          <StyledTypography
            variant="h4"
            style={{ marginTop: theme.spacing(2) }}
          >
            <Link href="/Subscriptions" color="inherit" underline="always">
              Or top up credits and search infidenetly
            </Link>
          </StyledTypography>
        </S.LeftContainer>

        <S.RightContainer style={isSmall ? { display: "none" } : {}}>
          <S.Ellipse>
            <S.Ellipse2>
              <S.ContainerWrapper src={bmwCarImage} alt={"car"} />
            </S.Ellipse2>
          </S.Ellipse>
        </S.RightContainer>
      </S.Wrapper>
    </S.Container>
  );
};
export default Home;
