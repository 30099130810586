import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { StyledContainer } from "../../GlobalComponents";
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles";
export const Container = styled(StyledContainer)``;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
`;

export const HeaderTitle = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export const StyledButton = withStyles((theme) => ({
  root: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "30px",

    backgroundColor: "#1dbf59",

    "&:hover": {
      backgroundColor: `${darken("#1dbf59", 0.2)}`,
    },
  },
}))(Button);

export const SearchTypography = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
  },
  Title: {
    ...theme.typography.h4,
    fontWeight: 700,
    lineHeight: "1.35",
    textAlign: "left",
    marginBottom: theme.spacing(5),
  },
  RowTitle: {
    ...theme.typography.h6,
    color: theme.palette.primary.light,
  },
  Row: {
    ...theme.typography.h6,
    fontWeight: 700,
  },
  Settings: {
    ...theme.typography.h6,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    fontWeight: 700,
  },
}))(Typography);
