import React, { useState } from "react";
import { IconButton, ListItem, ListItemText } from "@material-ui/core";
import * as S from "./Drawer.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useTheme } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import BavarianVIN from "../../../images/BavarianVIN.png";
import bmwLogo from "../../../images/bmwLogoWhite.png";

import MenuIcon from "@material-ui/icons/Menu";

const DrawerComponent = () => {
  const theme = useTheme();

  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <S.StyledDrawer
        // BackdropProps={{ style: { opacity: 0 } }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <S.List>
          <S.LinkItem to="/" onClick={() => setOpenDrawer(false)}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: theme.palette.primary.darkBlue }}
                >
                  <img src={bmwLogo} alt="Logo" height={20} width={20} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText style={{ marginLeft: "-30px" }}>
                <img src={BavarianVIN} alt="Logo" height={40} width={200} />
              </ListItemText>
            </ListItem>
          </S.LinkItem>
          <S.LinkItem to="/" onClick={() => setOpenDrawer(false)}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: theme.palette.primary.darkBlue }}
                >
                  <FontAwesomeIcon icon="home" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <S.DrawerTypography variant="h5">Home</S.DrawerTypography>
              </ListItemText>
            </ListItem>
          </S.LinkItem>
          <S.LinkItem to="/Pricing" onClick={() => setOpenDrawer(false)}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: theme.palette.primary.darkBlue }}
                >
                  <FontAwesomeIcon icon="shopping-cart" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <S.DrawerTypography variant="h5">Pricing</S.DrawerTypography>
              </ListItemText>
            </ListItem>
          </S.LinkItem>
          <S.LinkItem to="/Purchases" onClick={() => setOpenDrawer(false)}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: theme.palette.primary.darkBlue }}
                >
                  <FontAwesomeIcon icon="money-check" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <S.DrawerTypography variant="h5">Purchases</S.DrawerTypography>
              </ListItemText>
            </ListItem>
          </S.LinkItem>
          <S.LinkItem to="/FAQ" onClick={() => setOpenDrawer(false)}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: theme.palette.primary.darkBlue }}
                >
                  <FontAwesomeIcon icon="question" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <S.DrawerTypography variant="h5">FAQ</S.DrawerTypography>
              </ListItemText>
            </ListItem>
          </S.LinkItem>
          <S.LinkItem to="/Subscriptions" onClick={() => setOpenDrawer(false)}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: theme.palette.primary.darkBlue }}
                >
                  <FontAwesomeIcon icon="wallet" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <S.DrawerTypography variant="h5">
                  Subscriptions
                </S.DrawerTypography>
              </ListItemText>
            </ListItem>
          </S.LinkItem>
        </S.List>
      </S.StyledDrawer>
      <IconButton
        style={{ maxHeight: "70px" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon
          style={{ width: "30px", height: "30px", minHeight: "70px" }}
        />
      </IconButton>
    </>
  );
};
export default DrawerComponent;
