import React, { useState, useEffect, useContext } from "react";

import { useTheme, useMediaQuery } from "@material-ui/core";
import Search from "../GlobalComponents/Search";

import * as S from "./Search.styled";
import RecentSearchBig from "./RecentSearchBig";
import RecentSearch from "./RecentSearch";
import axios from "axios";
import ContextApi from "../../ContextApi";
import Loading from "../GlobalComponents/Loading";
import LoadingCircle from "../GlobalComponents/LoadingCircle";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSearch from "../GlobalComponents/LoadingSearch";
import useStore from "../../utils/useStore";
import { observer } from "mobx-react";

const Pricing = () => {
  const PropsContext = useContext(ContextApi);

  const [isLoading, setLoading] = useState(true);

  const [services, setServices] = useState(null);
  const [regions, setRegionProps] = useState(null);
  const [regionVersion, setRegionVersionProps] = useState(null);
  const [recentSearches, setRecentSearches] = useState(null);
  const [hasMore, sethasMore] = useState(true);
  const [offset, setOffset] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [currentVin, setCurrentVin] = useState(null);
  const [finished, setFinished] = useState(false);
  const theme = useTheme();
  const { store } = useStore();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (PropsContext.auth && !PropsContext.isLoading) {
      setServices(PropsContext.productProps.services);
      setRegionProps(PropsContext.regionProps);
      setRegionVersionProps(PropsContext.regionVersionProps);
      const getSearches = () => {
        const res = axios
          .get(
            process.env.REACT_APP_API_URL + `/search-history?offset=0&limit=2`,
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setRecentSearches(res.data);
            setLoading(false);
            setOffset(2);
            sethasMore(true);
            setFinished(false);
          })
          .catch((error) => {
            store.callToast(error.response.data, "Error");
          });
      };
      getSearches();
    }
  }, [PropsContext.isLoading, isSearching]);

  const fetchSearches = async () => {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          `/search-history?offset=${offset}&limit=2`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        store.callToast(error.response.data, "Error");
      });
  };

  const fetchMoreData = async () => {
    const moreSearches = await fetchSearches();

    setRecentSearches([...recentSearches, ...moreSearches]);
    if (moreSearches.length === 0 || moreSearches.length < 2) {
      sethasMore(false);
    }
    setOffset(offset + 2);
  };

  let firstDialog;

  if (isLoading || PropsContext.isLoading) {
    return <Loading />;
  }

  if (isSearching) {
    return <LoadingSearch currentVin={currentVin} finished={finished} />;
  }

  if (recentSearches === null || recentSearches.length === 0) {
    <div> No searches Found... </div>;
  }
  if (isSmall) {
    firstDialog = (
      <>
        <S.SearchTypography variant="Title">Recent Searches</S.SearchTypography>
        <RecentSearch
          carProps={recentSearches[0]}
          services={services}
          regions={regions}
          regionVersion={regionVersion}
          currentVin={currentVin}
          setCurrentVin={setCurrentVin}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          finished={finished}
          setFinished={setFinished}
        />
      </>
    );
  } else {
    firstDialog = (
      <>
        <S.SearchTypography variant="Title">Recent Searches</S.SearchTypography>
        <RecentSearchBig
          carProps={recentSearches[0]}
          services={services}
          regions={regions}
          regionVersion={regionVersion}
          currentVin={currentVin}
          setCurrentVin={setCurrentVin}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          finished={finished}
          setFinished={setFinished}
        />
      </>
    );
  }
  return (
    <S.Container>
      <S.Wrapper>
        <Search
          isSearching={isSearching}
          setIsSearching={setIsSearching}
          currentVin={currentVin}
          setCurrentVin={setCurrentVin}
          finished={finished}
          setFinished={setFinished}
        />

        {firstDialog}
        {recentSearches.length > 1 && (
          <InfiniteScroll
            dataLength={recentSearches.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoadingCircle />}
          >
            {recentSearches.slice(1).map((props) => {
              return (
                <RecentSearch
                  carProps={props}
                  services={services}
                  regions={regions}
                  regionVersion={regionVersion}
                  currentVin={currentVin}
                  setCurrentVin={setCurrentVin}
                  isSearching={isSearching}
                  setIsSearching={setIsSearching}
                  finished={finished}
                  setFinished={setFinished}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default observer(Pricing);
