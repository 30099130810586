import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { createTheme } from "@material-ui/core/styles";
import getThemeType from "./utils/getThemeType";

const getCustomThemeStyles = ({ returnReversedTheme, forceType }) => {
  let type = getThemeType();

  if (returnReversedTheme) {
    type = type === "light" ? "dark" : "light";
  }

  if (forceType) {
    type = forceType;
  }

  const customThemeStyles = {
    palette: {
      type,
      primary: {
        darkBlue: "#1f2441",
        light: "#fff",
        main: "#027bfe",
        dark: "#000000",
        contrastText: "#FFFFFF",
        titleBlue: "#b6bad5",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
      warning: {
        light: amber[500],
        main: amber[700],
        dark: amber[900],
        contrastText: "#FFFFFF",
      },
      error: {
        light: red[500],
        main: red[700],
        dark: red[900],
        contrastText: "#FFFFFF",
      },
      success: {
        light: green[500],
        main: green[700],
        dark: green[900],
        contrastText: "#FFFFFF",
      },
    },
    typography: {
      button: {
        textTransform: "none",
        fontFamily: "proxima-nova, sans-serif;",
      },
    },
    overrides: {
      MuiBackdrop: {
        root: {},
      },
    },
  };

  return createTheme(customThemeStyles);
};

export default getCustomThemeStyles;
const theme = getCustomThemeStyles({});
